/* eslint-disable jsx-a11y/anchor-is-valid */
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { BMEnterFrameEvent } from "lottie-web";
import { AnimationEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import loginIllustration from "../../assets/animations/login.json";
import translateIllustration from "../../assets/animations/translate-illustration.json";
import useAuth from "../../services/Auth/AuthService";
import ResetPasswordDialog from "../ResetPassword/ResetPasswordDialog/ResetPasswordDialog";
import "./Login.css";
import { base64URLEncode, generateNonce, sha256 } from "../../utils/helpers";

interface FormValues {
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

function Login() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({ mode: "all" });
  const navigate = useNavigate();
  const auth = useAuth();
  const [newPasswordChallenge, setNewPasswordChallenge] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [searchParams] = useSearchParams();
  const [openResetPasswordDialog, setOpenResetPasswordDialog] =
    useState<boolean>(false);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (searchParams.has("code")) {
      auth.oAuthLogin(String(searchParams.get("code")));
    }
    if (searchParams.has("error_description")) {
      if (
        searchParams.get("error_description") &&
        searchParams.get("error_description")?.includes("PreSignUp")
      ) {
        setTimeout(() => {
          toast.error(t("errors.already-registered"));
          navigate("/login");
        }, 500);
      }
    }
  }, [searchParams]);

  const login = async (email: string, password: string) => {
    try {
      const cognitoUser = await auth.login(email, password);
      if (cognitoUser) {
        setUser(cognitoUser);
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          setNewPasswordChallenge(true);
        } else {
          toast.success(t("login.login-success"));
        }
      } else {
        toast.error(t("login.incorrect-credentials"));
      }
    } catch (err) {
      toast.error(t("login.incorrect-credentials"));
    }
  };

  const confirmNewPassword = async (
    cognitoUser: CognitoUser,
    newPassword: string,
    newPasswordConfirmation: string,
    email: string
  ) => {
    if (newPassword === newPasswordConfirmation) {
      const response: CognitoUser | undefined = await auth.confirmNewPassword(
        cognitoUser,
        newPassword
      );
      if (response && response.getSignInUserSession()) {
        login(email, newPassword);
      }
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!newPasswordChallenge) {
      login(data.email, data.password);
    } else if (user) {
      confirmNewPassword(
        user,
        data.newPassword,
        data.confirmNewPassword,
        data.email
      );
    }
  };

  const federatedLogin = async (provider: "Google" | "SignInWithApple") => {
    const state = await generateNonce();
    const codeVerifier = await generateNonce();
    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const codeChallenge = base64URLEncode(await sha256(codeVerifier));
    if (
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_URL &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_DOMAIN &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL &&
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID
    ) {
      const url = process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_URL.replace(
        "DOMAIN",
        process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_DOMAIN
      )
        .replace("IDENTITY_PROVIDER", provider)
        .replace(
          "REDIRECT_URL",
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL
        )
        .replace(
          "USERPOOLWEBCLIENTID",
          process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID
        )
        .replace("STATE", state)
        .replace("CODECHALLENGE", codeChallenge);
      window.location.href = url;
    }
  };

  const handleClose = async (returnValue: string) => {
    setOpenResetPasswordDialog(false);
    if (returnValue !== "closed") {
      try {
        await auth.resetPasswordRequest(returnValue);
        toast.success(t("reset-password.code-sent"));
        navigate(`/reset-password?email=${encodeURIComponent(returnValue)}`);
      } catch (err) {
        toast.error(t("reset-password.code-sending-error"));
      }
    }
  };

  const handleErrorText = (field: string, type?: string): string => {
    if (type) {
      if (type === "required") {
        return t("errors.required-field");
      }
      if (type === "pattern" && field === "email") {
        return t("errors.invalid-email");
      }
      if (type === "pattern" && field === "password") {
        return t("errors.password-pattern");
      }
      if (type === "validate") {
        return t("errors.password-must-match");
      }
    }
    return "";
  };

  return (
    <Grid container>
      <ResetPasswordDialog
        open={openResetPasswordDialog}
        onClose={handleClose}
      />
      {!isMobile && (
        <Grid item xs={6}>
          <Lottie
            alt={t("alt.login-animation")}
            lottieRef={lottieRef}
            animationData={translateIllustration}
            style={{
              width: "50vw",
              height: "100vh",
              backgroundColor: "#F3F7FD",
              overflowY: "hidden",
            }}
            onDataReady={() => lottieRef.current?.setSpeed(0.4)}
            onEnterFrame={(res: AnimationEvent<Element> | null) => {
              const event = res as unknown as BMEnterFrameEvent;
              if (event.currentTime > 179) {
                lottieRef.current?.setDirection(-1);
              } else if (event.currentTime < 1 && event.direction === -1) {
                lottieRef.current?.setDirection(1);
              }
            }}
          />
        </Grid>
      )}
      <Grid
        item
        className="mobile-grid"
        xs={12}
        md={6}
        sx={{ alignSelf: "center", textAlign: "center" }}
      >
        {isMobile ? (
          <Lottie
            alt={t("alt.login-animation-mobile")}
            animationData={loginIllustration}
          />
        ) : null}
        <Grid container sx={{ placeContent: "center" }}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 64,
                width: 72,
                mb: 2,
              }}
              alt={t("alt.logo")}
              src="../logo.png"
            />
          </Grid>
          <Grid item sx={{ alignSelf: "center", ml: 1, mb: 2.5 }}>
            <Typography
              component="h1"
              variant="h1"
              className="text-gradient"
              sx={{ fontFamily: "LamaRounded" }}
            >
              trAPP
            </Typography>
          </Grid>
        </Grid>
        <Typography component="h2" variant="h6" sx={{ mb: 5 }}>
          {t("login.login")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid item sx={{ mb: 2 }}>
              <TextField
                variant="filled"
                label={`${t("login.email")}`}
                sx={{ width: "65%" }}
                autoComplete="email"
                disabled={newPasswordChallenge}
                {...register("email", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                helperText={handleErrorText("email", errors.email?.type)}
                data-cy="emailField"
              />
            </Grid>
            <Grid item sx={{ mb: newPasswordChallenge ? 2 : 1 }}>
              <TextField
                type="password"
                variant="filled"
                label={`${t("login.password")}`}
                sx={{ width: "65%" }}
                disabled={newPasswordChallenge}
                autoComplete="current-password"
                {...register("password", { required: true })}
                helperText={
                  errors.password?.type === "required"
                    ? t("errors.required-field")
                    : ""
                }
                data-cy="passwordField"
              />
            </Grid>
            {newPasswordChallenge ? (
              <>
                <Grid item sx={{ mb: 2 }}>
                  <TextField
                    type="password"
                    variant="filled"
                    label={t("login.new-password")}
                    sx={{ width: "65%" }}
                    helperText={handleErrorText(
                      "password",
                      errors.newPassword?.type
                    )}
                    {...register("newPassword", {
                      required: true,
                      pattern:
                        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                    })}
                    data-cy="newPasswordField"
                  />
                </Grid>
                <Grid item sx={{ mb: 5 }}>
                  <TextField
                    type="password"
                    variant="filled"
                    label={t("login.confirm-new-password")}
                    sx={{ width: "65%" }}
                    helperText={handleErrorText(
                      "password",
                      errors.confirmNewPassword?.type
                    )}
                    {...register("confirmNewPassword", {
                      required: true,
                      pattern:
                        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                      validate: (val: string) => {
                        const { newPassword } = getValues();
                        return newPassword === val;
                      },
                    })}
                    data-cy="confirmNewPasswordField"
                  />
                </Grid>
              </>
            ) : null}
            {!newPasswordChallenge ? (
              <Grid
                item
                sx={{
                  width: "82%",
                  textAlign: "right",
                  mb: 5,
                  mt: errors.password ? "-2rem" : "-0.5rem",
                  zIndex: 1000,
                }}
              >
                <Link
                  sx={{
                    cursor: "pointer",
                    fontSize: "0.875rem",
                    fontFamily: "Nunito Sans",
                  }}
                  onClick={() => setOpenResetPasswordDialog(true)}
                  data-cy="forgotPasswordLink"
                >
                  {t("login.forgot-password")}
                </Link>
              </Grid>
            ) : null}
            <Grid item sx={{ maxHeight: "56.5px" }}>
              {!newPasswordChallenge ? (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "60%",
                    borderRadius: "16px",
                    p: "16px 24px",
                    textTransform: "none",
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                  disabled={!isValid}
                >
                  {t("login.access")}
                </Button>
              ) : null}
              {newPasswordChallenge ? (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "50%",
                    borderRadius: "16px",
                    p: "16px 24px",
                    textTransform: "none",
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                  disabled={newPasswordChallenge && !isValid}
                >
                  {t("login.confirm-password-login")}
                </Button>
              ) : null}
            </Grid>
            <Grid item sx={{ width: "65%", margin: "6rem auto" }}>
              <Divider>
                <Typography variant="caption" sx={{ mt: -1 }}>
                  {t("login.or")}
                </Typography>
              </Divider>
            </Grid>
            <Grid container sx={{ placeContent: "center" }}>
              <Grid item>
                <Button
                  variant="contained"
                  className="apple-button-hover"
                  sx={{
                    backgroundColor: "#000",
                    width: "50%",
                    borderRadius: "16px",
                    p: "16px 24px",
                  }}
                  onClick={() => federatedLogin("SignInWithApple")}
                >
                  <AppleIcon sx={{ color: "#fff" }} />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className="google-button-hover"
                  sx={{
                    backgroundColor: "#CB2027",
                    width: "50%",
                    borderRadius: "16px",
                    p: "16px 24px",
                  }}
                  onClick={() => federatedLogin("Google")}
                >
                  <GoogleIcon sx={{ color: "#fff" }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login;
