import { Box, Divider } from "@mui/material";
import { memo } from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
  onDragStartResponder,
} from "react-beautiful-dnd";
import { ProjectLanguage } from "../../models/Language";
import "./DraggableList.css";
import DraggableListItem from "./DraggableListItem";

export type DraggableListProps = {
  baseLanguage: boolean;
  items: ProjectLanguage[];
  index: number;
  onDragEnd: OnDragEndResponder;
  onDragStart: onDragStartResponder;
  handleDelete: (
    value: { code: string; label?: string },
    type: "add" | "remove" | "closed"
  ) => void;
};

const DraggableList = memo(
  ({
    items,
    index,
    onDragEnd,
    onDragStart,
    baseLanguage,
    handleDelete,
  }: DraggableListProps) => (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId={`droppable-list-${index}`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, i) => (
              <Box key={item.code}>
                <DraggableListItem
                  item={item}
                  index={i}
                  baseLanguage={baseLanguage}
                  handleDelete={handleDelete}
                />
                <Divider />
              </Box>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
);

export default DraggableList;
