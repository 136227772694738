import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { post } from "../../../../services/Api/ApiFunctions";

type ProjectsApiKeysCreationDialogProps = {
  open: boolean;
  onClose: (returnValue: string) => void;
  id: string;
};

interface FormValues {
  name: string;
}

function ProjectsApiKeysCreationDialog({
  open,
  onClose,
  id,
}: ProjectsApiKeysCreationDialogProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: "all" });

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isValid) {
      post(`/projects/${id}/api-keys`, { keyLabel: data.name })
        .then(() => {
          onClose("created");
          toast.success(t("api-key.key-creation-successful"));
        })
        .catch(() => {
          toast.error(t("api-keys.key-creation-error"));
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              id="projectsApiKeysCreationDialogTitle"
              data-cy="projectsApiKeysCreationDialogTitle"
              sx={{ pt: 1 }}
            >
              {t("api-key.create-api-key")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            variant="filled"
            label={`${t("api-key.key-label")}`}
            {...register("name", {
              required: true,
            })}
            helperText={
              errors.name?.type === "required" ? t("errors.required-field") : ""
            }
            sx={{ mr: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            type="submit"
            data-cy="projectsApiKeysCreationDialogButton"
            disabled={!isValid}
            variant="contained"
            sx={{ borderRadius: "25px" }}
          >
            {t("api-key.create-api-key")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ProjectsApiKeysCreationDialog;
