import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import "./Layout.css";

function Layout() {
  window.onscroll = () => {
    const backgroundElement = document.querySelector(
      ".container-class"
    ) as HTMLElement;
    if (backgroundElement) {
      const minScroll = window.innerHeight;
      const maxScroll = document.body.clientHeight - minScroll;

      const actualScroll = Math.floor(window.scrollY);

      const determinePercentage = () =>
        Math.floor((actualScroll * 100) / maxScroll);

      const actualPercentage = determinePercentage();

      backgroundElement.style.cssText = `background-position-x: ${actualPercentage}%;`;
    }
  };

  return (
    <Box className="container-class">
      <Box className="card" />
      <Box sx={{ zIndex: 2 }}>
        <Header />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}

export default Layout;
