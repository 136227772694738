import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { ChangeEvent } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PostProject } from "../../../../models/Project";
import { post } from "../../../../services/Api/ApiFunctions";
import languagesList from "../../../../utils/constants";
import { compare } from "../../../../utils/helpers";

type ProjectsCreationDialogProps = {
  open: boolean;
  onClose: (returnValue: string) => void;
};

interface FormValues {
  name: string;
  baseLanguageCode: string;
  country: string;
}

function ProjectsCreationDialog({
  open,
  onClose,
}: ProjectsCreationDialogProps) {
  const languages = languagesList.sort(compare);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useForm<FormValues>({ mode: "all" });

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    post<PostProject>("/projects", {
      name: data.name,
      baseLanguageCode: data.country,
    })
      .then(() => {
        toast.success(t("projects.project-creation-success"));
        onClose("created");
      })
      .catch(() => {
        toast.error(t("projects.project-creation-error"));
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              id="projectsCreationDialogTitle"
              data-cy="projectsCreationDialogTitle"
              sx={{ pt: 1 }}
            >
              {t("projects.create-project")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            variant="filled"
            label={`${t("projects.name")}`}
            {...register("name", {
              required: true,
            })}
            helperText={
              errors.name?.type === "required" ? t("errors.required-field") : ""
            }
            sx={{ mr: 2 }}
          />
          <FormControl>
            <InputLabel className="MuiSelect-label">
              {t("projects.base-language")}
            </InputLabel>
            <Controller
              name="baseLanguageCode"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { name, value, onChange, onBlur, ref } }) => (
                <Select
                  ref={ref}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  onChange={(event) => onChange(event as ChangeEvent)}
                  variant="filled"
                  label={`${t("projects.language")}`}
                  sx={{ width: "229px", mr: 2 }}
                >
                  {languages.map((language) => {
                    if (!language.value.includes("-")) {
                      return (
                        <MenuItem value={language.value} key={language.value}>
                          {language.value} ({language.title})
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              )}
            />
            {errors.baseLanguageCode?.type === "required" ? (
              <FormHelperText>{t("errors.required-field")}</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl>
            <InputLabel className="MuiSelect-label">
              {t("projects.country")}
            </InputLabel>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { name, value, onChange, onBlur, ref } }) => (
                <Select
                  disabled={!watch("baseLanguageCode")}
                  ref={ref}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  onChange={(event) => onChange(event as ChangeEvent)}
                  variant="filled"
                  label={`${t("projects.country")}`}
                  sx={{ width: "229px" }}
                >
                  {languages.map((language, i) => {
                    if (
                      language.value.includes("-") &&
                      watch("baseLanguageCode") === language.value.split("-")[0]
                    ) {
                      return (
                        <MenuItem value={language.value} key={language.value}>
                          {language.value.split("-")[2]
                            ? `${language.value.split("-")[1]}-${
                                language.value.split("-")[2]
                              }`
                            : language.value.split("-")[1]}{" "}
                          ({language.title})
                        </MenuItem>
                      );
                    }
                    if (
                      !language.value.includes("-") &&
                      languages.findIndex((x) =>
                        x.value.includes(`${language.value}-`)
                      ) === -1 &&
                      watch("baseLanguageCode") === language.value
                    ) {
                      return (
                        <MenuItem
                          value={`${language.value}-**`}
                          key={language.value}
                        >
                          {language.value} ({language.title})
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              )}
            />
            {errors.baseLanguageCode?.type === "required" ? (
              <FormHelperText>{t("errors.required-field")}</FormHelperText>
            ) : null}
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            type="submit"
            data-cy="createProjectButton"
            disabled={!isValid}
            variant="contained"
            sx={{ borderRadius: "25px" }}
          >
            {t("projects.create-project")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ProjectsCreationDialog;
