import { Theme } from "@mui/material";

const createThemeComponents = (theme: Theme) => ({
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.paper} inset`,
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        "& .MuiSelect-label.MuiInputLabel-shrink": {
          paddingTop: 20,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      wrapper: {
        marginTop: "-5px",
      },
    },
  },
});

export default createThemeComponents;
