const languagesList = [
  {
    value: "ab",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Abkhaz" : "Abkhazo",
  },
  {
    value: "aa",
    title: "Afar",
  },
  {
    value: "af",
    title: "Afrikaans",
  },
  {
    value: "ak",
    title: "Akan",
  },
  {
    value: "sq",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Albanian" : "Albanese",
  },
  {
    value: "sq-AL",
    title: "Albania",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Albanian" : "Albanese",
  },
  {
    value: "am",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Amharic" : "Amarico",
  },
  {
    value: "ar",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-DZ",
    title: "Algeria",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-DZ",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Algeria"
        : "Arabo, Algeria",
  },
  {
    value: "ar-BH",
    title: "Bahrain",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-BH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Bahrain"
        : "Arabo, Bahrain",
  },
  {
    value: "ar-EG",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Egypt" : "Egitto",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-EG",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Egypt"
        : "Arabo, Egitto",
  },
  {
    value: "ar-IQ",
    title: "Iraq",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-IQ",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Iraq"
        : "Arabo, Iraq",
  },
  {
    value: "ar-JO",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Jordan" : "Giorania",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-JO",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Jordan"
        : "Arabo, Giordania",
  },
  {
    value: "ar-KW",
    title: "Kuwait",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-KW",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Kuwait"
        : "Arabo, Kuwait",
  },
  {
    value: "ar-LB",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Lebanon" : "Libano",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-LB",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Lebanon"
        : "Arabo, Libano",
  },
  {
    value: "ar-LY",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Libya" : "Libia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-LY",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Libya"
        : "Arabo, Libia",
  },
  {
    value: "ar-MA",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Morocco" : "Marocco",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-MA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Morocco"
        : "Arabo, Marocco",
  },
  {
    value: "ar-OM",
    title: "Oman",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-OM",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Oman"
        : "Arabo, Oman",
  },
  {
    value: "ar-QA",
    title: "Qatar",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-QA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Qatar"
        : "Arabo, Qatar",
  },
  {
    value: "ar-SA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Saudi Arabia"
        : "Arabia Saudita",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-SA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Saudi Arabia"
        : "Arabo, Arabia Saudita",
  },
  {
    value: "ar-SD",
    title: "Sudan",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-SD",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Sudan"
        : "Arabo, Sudan",
  },
  {
    value: "ar-SY",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Syria" : "Siria",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-SY",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Syria"
        : "Arabo, Siria",
  },
  {
    value: "ar-TN",
    title: "Tunisia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-TN",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Tunisia"
        : "Arabo, Tunisia",
  },
  {
    value: "ar-AE",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "United Arab Emirates"
        : "Emirati Arabi Uniti",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-AE",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, United Arab Emirates"
        : "Arabo, Emirati Arabi Uniti",
  },
  {
    value: "ar-YE",
    title: "Yemen",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Arabic" : "Arabo",
  },
  {
    value: "ar-Arab-YE",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Arabic, Yemen"
        : "Arabo, Yemen",
  },
  {
    value: "an",
    title: "Aragonese",
  },
  {
    value: "hy",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Armenian" : "Armeno",
  },
  {
    value: "as",
    title: "Assamese",
  },
  {
    value: "av",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Avaric" : "Avaro",
  },
  {
    value: "ae",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Avestan" : "Avestico",
  },
  {
    value: "ay",
    title: "Aymara",
  },
  {
    value: "az",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Azerbaijani" : "Azero",
  },
  {
    value: "bm",
    title: "Bambara",
  },
  {
    value: "ba",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Bashkir" : "Baschiro",
  },
  {
    value: "eu",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Basque" : "Basco",
  },
  {
    value: "be",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Belarusian"
        : "Bielorusso",
  },
  {
    value: "be-BY",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Belarus"
        : "Bielorussia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Belarusian"
        : "Bielorusso",
  },
  {
    value: "be-Cyrl-BY",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Cyrillic, Belarus"
        : "Cirillico, Bielorussia",
  },
  {
    value: "bn",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Bengali" : "Bengalese",
  },
  {
    value: "bh",
    title: "Bihari",
  },
  {
    value: "bi",
    title: "Bislama",
  },
  {
    value: "bs",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Bosnian" : "Bosniaco",
  },
  {
    value: "br",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Breton" : "Bretone",
  },
  {
    value: "bg",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Bulgarian" : "Bulgaro",
  },
  {
    value: "bg-BG",
    title: "Bulgaria",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Bulgarian" : "Bulgaro",
  },
  {
    value: "bg-Cyrl-BG",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Cyrillic, Bulgaria"
        : "Cirillico, Bulgaria",
  },
  {
    value: "my",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Burmese" : "Birmano",
  },
  {
    value: "ca-ES",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Spain" : "Spagna",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Catalan Valencian"
        : "Catalano Valenciano",
  },
  {
    value: "ca",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Catalan Valencian"
        : "Catalano Valenciano",
  },
  {
    value: "ch",
    title: "Chamorro",
  },
  {
    value: "ce",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Chechen" : "Ceceno",
  },
  {
    value: "ny",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Chichewa, Chewa or Nyanja"
        : "Cinyanja, Chewa o Nyanja",
  },
  {
    value: "zh",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-HK",
    title: "Hong Kong",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-CN",
    title: window.navigator.language.slice(0, 2) !== "it" ? "China" : "Cina",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-Hans-CN",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Simplified, China"
        : "Semplificato, Cina",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-Hant-CN",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Traditional, China"
        : "Tradizionale, Cina",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-SG",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Singapore"
        : "Singapore",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "zh-TW",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Traditional, Taiwan"
        : "Tradizionale, Taiwan",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Chinese" : "Cinese",
  },
  {
    value: "cv",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Chuvash" : "Ciuvascio",
  },
  {
    value: "kw",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Cornish" : "Cornico",
  },
  {
    value: "co",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Corsican" : "Corso",
  },
  {
    value: "cr",
    title: "Cree",
  },
  {
    value: "hr",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Croatian" : "Croato",
  },
  {
    value: "hr-HR",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Croatia" : "Croazia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Croatian" : "Croato",
  },
  {
    value: "cs",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Czech" : "Ceco",
  },
  {
    value: "cs-CZ",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Czech Republic"
        : "Repubblica Ceca",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Czech" : "Ceco",
  },
  {
    value: "da",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Danish" : "Danese",
  },
  {
    value: "da-DK",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Denmark" : "Danimarca",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Danish" : "Danese",
  },
  {
    value: "dv",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Divehi, Dhivehi or Maldivian"
        : "Divehi, Dhivehi o Maldiviano",
  },
  {
    value: "nl",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Dutch" : "Olandese",
  },
  {
    value: "nl-BE",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Belgium" : "Belgio",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Dutch" : "Olandese",
  },
  {
    value: "nl-NL",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Netherlands" : "Olanda",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Dutch" : "Olandese",
  },
  {
    value: "en",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-AU",
    title: "Australia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-CA",
    title: "Canada",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-IN",
    title: "India",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-IE",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Ireland" : "Irlanda",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-MT",
    title: "Malta",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-NZ",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "New Zealand"
        : "Nuova Zelanda",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-PH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Philippines"
        : "Filippine",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-SG",
    title: "Singapore",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-ZA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "South Africa"
        : "Sud Africa",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-GB",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "United Kingdom"
        : "Regno Unito",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "en-US",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "United States"
        : "Stati Uniti d'America",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "English" : "Inglese",
  },
  {
    value: "eo",
    title: "Esperanto",
  },
  {
    value: "et",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Estonian" : "Estone",
  },
  {
    value: "et-EE",
    title: "Estonia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Estonian" : "Estone",
  },
  {
    value: "ee",
    title: "Ewe",
  },
  {
    value: "fo",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Faroese" : "Faringio",
  },
  {
    value: "fj",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Fijian" : "Figiano",
  },
  {
    value: "fi",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Finnish" : "Finlandese",
  },
  {
    value: "fi-FI",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Finland" : "Finlandia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Finnish" : "Finlandese",
  },
  {
    value: "fr",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "fr-BE",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Belgium" : "Belgio",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "fr-CA",
    title: "Canada",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "fr-FR",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "France" : "Francia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "fr-LU",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Luxembourg"
        : "Lussemburgo",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "fr-CH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Switzerland"
        : "Svizzera",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "French" : "Francese",
  },
  {
    value: "ff",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Fula, Fulah, Pulaar or Pular"
        : "Fula, Peul, Fulah o Fulani",
  },
  {
    value: "gl",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Galician" : "Gaelico",
  },
  {
    value: "ka",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Georgian" : "Georgiano",
  },
  {
    value: "de",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "German" : "Tedesco",
  },
  {
    value: "de-AT",
    title: "Austria",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "German" : "Tedesco",
  },
  {
    value: "de-DE",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Germany" : "Germania",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "German" : "Tedesco",
  },
  {
    value: "de-LU",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Luxembourg"
        : "Lussemburgo",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "German" : "Tedesco",
  },
  {
    value: "de-CH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Switzerland"
        : "Svizzera",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "German" : "Tedesco",
  },
  {
    value: "el-CY",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Cyprus" : "Cipro",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Greek, Modern"
        : "Greco, Moderno",
  },
  {
    value: "el-GR",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Greece" : "Grecia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Greek, Modern"
        : "Greco, Moderno",
  },
  {
    value: "el-Grek-GR",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Greek, Greece"
        : "Greco, Grecia",
  },
  {
    value: "el",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Greek, Modern"
        : "Greco, Moderno",
  },
  {
    value: "gn",
    title: "Guaraní",
  },
  {
    value: "gu",
    title: "Gujarati",
  },
  {
    value: "ht",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Haitian, Haitian Creole"
        : "Haitiano, Creolo Haitiano",
  },
  {
    value: "ha",
    title: "Hausa",
  },
  {
    value: "iw",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Hebrew" : "Ebraico",
  },
  {
    value: "iw-IL",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Israel" : "Israele",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Hebrew" : "Ebraico",
  },
  {
    value: "iw-Hebr-IL",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Hebrew, Israel"
        : "Ebraico, Israele",
  },
  {
    value: "he",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Hebrew, modern"
        : "Ebraico, moderno",
  },
  {
    value: "hz",
    title: "Herero",
  },
  {
    value: "hi",
    title: "Hindi",
  },
  {
    value: "hi-IN",
    title: "India",
    lang: "Hindi",
  },
  {
    value: "hi-Deva-IN",
    title: "Devanagari, India",
    lang: "Hindi",
  },
  {
    value: "ho",
    title: "Hiri Motu",
  },
  {
    value: "hu",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Hungarian"
        : "Ungherese",
  },
  {
    value: "hu-HU",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Hungary" : "Ungheria",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Hungarian"
        : "Ungherese",
  },
  {
    value: "is",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Icelandic"
        : "Islandese",
  },
  {
    value: "is-IS",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Iceland" : "Islanda",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Icelandic"
        : "Islandese",
  },
  {
    value: "io",
    title: "Ido",
  },
  {
    value: "ig",
    title: "Igbo",
  },
  {
    value: "id",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Indonesian"
        : "Indonesiano",
  },
  {
    value: "in-ID",
    title: "Indonesia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Indonesian"
        : "Indonesiano",
  },
  {
    value: "ia",
    title: "Interlingua",
  },
  {
    value: "ie",
    title: "Interlingue",
  },
  {
    value: "iu",
    title: "Inuktitut",
  },
  {
    value: "ik",
    title: "Inupiaq",
  },
  {
    value: "ga",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Irish" : "Irlandese",
  },
  {
    value: "ga-IE",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Ireland" : "Irlanda",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Irish" : "Irlandese",
  },
  {
    value: "it",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Italian" : "Italiano",
  },
  {
    value: "it-IT",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Italy" : "Italia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Italian" : "Italiano",
  },
  {
    value: "it-CH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Switzerland"
        : "Svizzera",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Italian" : "Italiano",
  },
  {
    value: "ja",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Japanese"
        : "Giapponese",
  },
  {
    value: "ja-JP",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Japan" : "Giappone",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Japanese"
        : "Giapponese",
  },
  {
    value: "ja-Jpan-JP",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Kanji, Hiragana, Katakana, Japan"
        : "Kanji, Hiragana, Katakana, Giappone",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Japanese"
        : "Giapponese",
  },
  {
    value: "jv",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Javanese" : "Giavanese",
  },
  {
    value: "kl",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Kalaallisut, Greenlandic"
        : "Kalaallisut, Groenlandese",
  },
  {
    value: "kn",
    title: "Kannada",
  },
  {
    value: "kr",
    title: "Kanuri",
  },
  {
    value: "ks",
    title: "Kashmiri",
  },
  {
    value: "kk",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Kazakh" : "Kazako",
  },
  {
    value: "km",
    title: "Khmer",
  },
  {
    value: "ki",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Kikuyu or Gikuyu"
        : "Kikuyu o Gikuyu",
  },
  {
    value: "rw",
    title: "Kinyarwanda",
  },
  {
    value: "ky",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Kirghiz, Kyrgyz"
        : "Kirghiso",
  },
  {
    value: "rn",
    title: "Kirundi",
  },
  {
    value: "kv",
    title: "Komi",
  },
  {
    value: "kg",
    title: "Kongo",
  },
  {
    value: "ko",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Korean" : "Coreano",
  },
  {
    value: "ko-KR",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "South Korea"
        : "Sud Corea",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Korean" : "Coreano",
  },
  {
    value: "ko-Kore-KR",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Han, Hangul, South Korea"
        : "Han, Hangul, Sud Corea",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Korean" : "Coreano",
  },
  {
    value: "ku",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Kurdish" : "Curdo",
  },
  {
    value: "kj",
    title: "Kwanyama, Kuanyama",
  },
  {
    value: "lo",
    title: "Lao",
  },
  {
    value: "la",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Latin" : "Latino",
  },
  {
    value: "lv",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Latvian" : "Lettone",
  },
  {
    value: "lv-LV",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Latvia" : "Lettonia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Latvian" : "Lettone",
  },
  {
    value: "li",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Limburgish, Limburgan, Limburger"
        : "Limburghese",
  },
  {
    value: "ln",
    title: "Lingala",
  },
  {
    value: "lt",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Lithuanian" : "Lituano",
  },
  {
    value: "lt-LT",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Lithuania" : "Lituania",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Lithuanian" : "Lituano",
  },
  {
    value: "lu",
    title: "Luba-Katanga",
  },
  {
    value: "lg",
    title: "Luganda",
  },
  {
    value: "lb",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Luxembourgish, Letzeburgesch"
        : "Lussemburghese",
  },
  {
    value: "mk",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Macedonian"
        : "Macedone",
  },
  {
    value: "mk-MK",
    title: "Macedonia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Macedonian"
        : "Macedone",
  },
  {
    value: "mg",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Malagasy" : "Malgascio",
  },
  {
    value: "ms",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Malay" : "Malese",
  },
  {
    value: "ms-MY",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Malaysia" : "Malesia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Malay" : "Malese",
  },
  {
    value: "ml",
    title: "Malayalam",
  },
  {
    value: "mt",
    title: "Maltese",
  },
  {
    value: "mt-MT",
    title: "Malta",
    lang: "Maltese",
  },
  {
    value: "gv",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Manx" : "Mannese",
  },
  {
    value: "mr",
    title: "Marathi",
  },
  {
    value: "mh",
    title: "Marshallese",
  },
  {
    value: "mn",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Mongolian" : "Mongolo",
  },
  {
    value: "mi",
    title: "Māori",
  },
  {
    value: "na",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Nauru" : "Nauruano",
  },
  {
    value: "nv",
    title: "Navajo, Navaho",
  },
  {
    value: "ng",
    title: "Ndonga",
  },
  {
    value: "ne",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Nepali" : "Nepalese",
  },
  {
    value: "nd",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "North Ndebele"
        : "Ndebele del Nord",
  },
  {
    value: "se",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Northern Sami"
        : "Sami del Nord",
  },
  {
    value: "no",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Norwegian"
        : "Norvegese",
  },
  {
    value: "no-NO",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Norway" : "Norvegia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Norwegian"
        : "Norvegese",
  },
  {
    value: "nb",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Norwegian Bokmål"
        : "Bokmål Norvegese",
  },
  {
    value: "nn",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Norwegian Nynorsk"
        : "Nynorsk Norvegese",
  },
  {
    value: "ii",
    title: "Nuosu",
  },
  {
    value: "oc",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Occitan" : "Occitano",
  },
  {
    value: "oj",
    title: "Ojibwe, Ojibwa",
  },
  {
    value: "cu",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic"
        : "Slavo ecclesiastico",
  },
  {
    value: "or",
    title: "Oriya",
  },
  {
    value: "om",
    title: "Oromo",
  },
  {
    value: "os",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Ossetian, Ossetic"
        : "Osseto",
  },
  {
    value: "pa",
    title: "Panjabi, Punjabi",
  },
  {
    value: "ps",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Pashto, Pushto"
        : "Pashtu, Pashto",
  },
  {
    value: "fa",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Persian" : "Persiano",
  },
  {
    value: "pl",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Polish" : "Polacco",
  },
  {
    value: "pl-PL",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Poland" : "Polonia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Polish" : "Polacco",
  },
  {
    value: "pt",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Portuguese"
        : "Portoghese",
  },
  {
    value: "pt-BR",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Brazil" : "Brasile",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Portuguese"
        : "Portoghese",
  },
  {
    value: "pt-PT",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Portugal"
        : "Portogallo",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Portuguese"
        : "Portoghese",
  },
  {
    value: "pi",
    title: "Pāli",
  },
  {
    value: "qu",
    title: "Quechua",
  },
  {
    value: "ro-RO",
    title: "Romania",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Romanian, Moldavian, Moldovan"
        : "Rumeno, Moldavo",
  },
  {
    value: "ro",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Romanian, Moldavian, Moldovan"
        : "Rumeno, Moldavo",
  },
  {
    value: "rm",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Romansh" : "Romancio",
  },
  {
    value: "ru",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Russian" : "Russo",
  },
  {
    value: "ru-RU",
    title: "Russia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Russian" : "Russo",
  },
  {
    value: "ru-Cyrl-RU",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Cyrillic, Russia"
        : "Cirillico, Russia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Russian" : "Russo",
  },
  {
    value: "sm",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Samoan" : "Samoano",
  },
  {
    value: "sg",
    title: "Sango",
  },
  {
    value: "sa",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Sanskrit" : "Sanscrito",
  },
  {
    value: "sc",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Sardinian" : "Sardo",
  },
  {
    value: "gd",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Scottish Gaelic, Gaelic"
        : "Gaelico Scozzese, Gaelico",
  },
  {
    value: "sr",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Serbian" : "Serbo",
  },
  {
    value: "sr-BA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Bosnia and Herzegovina"
        : "Bosnia ed Erzegovina",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Serbian" : "Serbo",
  },
  {
    value: "sr-ME",
    title: "Montenegro",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Serbian" : "Serbo",
  },
  {
    value: "sr-RS",
    title: "Serbia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Serbian" : "Serbo",
  },
  {
    value: "sr-Cyrl-RS",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Cyrillic, Serbia"
        : "Cirillico, Serbia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Serbian" : "Serbo",
  },
  {
    value: "sn",
    title: "Shona",
  },
  {
    value: "sd",
    title: "Sindhi",
  },
  {
    value: "si",
    title: "Sinhala, Sinhalese",
  },
  {
    value: "sk",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Slovak" : "Slovacco",
  },
  {
    value: "sk-SK",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Slovakia"
        : "Slovacchia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Slovak" : "Slovacco",
  },
  {
    value: "sl",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Slovenian" : "Sloveno",
  },
  {
    value: "sl-SI",
    title: "Slovenia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Slovenian" : "Sloveno",
  },
  {
    value: "so",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Somali" : "Somalo",
  },
  {
    value: "nr",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "South Ndebele"
        : "Ndebele del Sud",
  },
  {
    value: "st",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Southern Sotho"
        : "Sotho del Sud",
  },
  {
    value: "es-AR",
    title: "Argentina",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-BO",
    title: "Bolivia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-CL",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Chile" : "Cile",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-CO",
    title: "Colombia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-CR",
    title: "Costa Rica",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-DO",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Dominican Republic"
        : "Repubblica Dominicana",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-EC",
    title: "Ecuador",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-SV",
    title: "El Salvador",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-GT",
    title: "Guatemala",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-HN",
    title: "Honduras",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-MX",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Mexico" : "Messico",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-NI",
    title: "Nicaragua",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-PA",
    title: "Panama",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-PY",
    title: "Paraguay",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-PE",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Peru" : "Perù",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-PR",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Puerto Rico"
        : "Porto Rico",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-ES",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Spain" : "Spagna",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-US",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "United States"
        : "Stati Uniti d'America",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-UY",
    title: "Uruguay",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es-VE",
    title: "Venezuela",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "es",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Spanish, Castilian"
        : "Spagnolo, Castigliano",
  },
  {
    value: "su",
    title: "Sundanese",
  },
  {
    value: "sw",
    title: "Swahili",
  },
  {
    value: "ss",
    title: "Swati",
  },
  {
    value: "sv",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Swedish" : "Svedese",
  },
  {
    value: "sv-SE",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Sweden" : "Svezia",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Swedish" : "Svedese",
  },
  {
    value: "tl",
    title: "Tagalog",
  },
  {
    value: "ty",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Tahitian" : "Tahitiano",
  },
  {
    value: "tg",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Tajik" : "Tagico",
  },
  {
    value: "ta",
    title: "Tamil",
  },
  {
    value: "tt",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Tatar" : "Tataro",
  },
  {
    value: "te",
    title: "Telugu",
  },
  {
    value: "th",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Thai" : "Thailandese",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Thailand"
        : "Thailandia",
  },
  {
    value: "th-TH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Thailand"
        : "Thailandia",
  },
  {
    value: "th-Thai-TH",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Thai, Thailand"
        : "Thai, Thailandia",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Thailand"
        : "Thailandia",
  },
  {
    value: "bo",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Tibetan Standard, Tibetan, Central"
        : "Tibetano Standard, Tibetano, Centrale",
  },
  {
    value: "ti",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Tigrinya" : "Tigrina",
  },
  {
    value: "to",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Tonga Islands"
        : "Isole Tonga",
  },
  {
    value: "ts",
    title: "Tsonga",
  },
  {
    value: "tn",
    title: "Tswana",
  },
  {
    value: "tr",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Turkish" : "Turco",
  },
  {
    value: "tr-TR",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Turkey" : "Turchia",
    lang: window.navigator.language.slice(0, 2) !== "it" ? "Turkish" : "Turco",
  },
  {
    value: "tk",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Turkmen" : "Turkmeno",
  },
  {
    value: "tw",
    title: "Twi",
  },
  {
    value: "ug",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Uighur, Uyghur"
        : "Uiguro",
  },
  {
    value: "uk",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Ukrainian" : "Ucraino",
  },
  {
    value: "uk-UA",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Ukraine" : "Ucraina",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Ukrainian" : "Ucraino",
  },
  {
    value: "uk-Cyrl-UA",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Cyrillic, Ukraine"
        : "Cirillico, Ucraina",
    lang:
      window.navigator.language.slice(0, 2) !== "it" ? "Ukrainian" : "Ucraino",
  },
  {
    value: "ur",
    title: "Urdu",
  },
  {
    value: "uz",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Uzbek" : "Uzbeko",
  },
  {
    value: "ve",
    title: "Venda",
  },
  {
    value: "vi",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Vietnamese"
        : "Vietnamita",
  },
  {
    value: "vi-VN",
    title: "Vietnam",
    lang:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Vietnamese"
        : "Vietnamita",
  },
  {
    value: "vo",
    title: "Volapük",
  },
  {
    value: "wa",
    title:
      window.navigator.language.slice(0, 2) !== "it" ? "Walloon" : "Vallone",
  },
  {
    value: "cy",
    title: window.navigator.language.slice(0, 2) !== "it" ? "Welsh" : "Gallico",
  },
  {
    value: "fy",
    title:
      window.navigator.language.slice(0, 2) !== "it"
        ? "Western Frisian"
        : "Frisiano Occidentale",
  },
  {
    value: "wo",
    title: "Wolof",
  },
  {
    value: "xh",
    title: "Xhosa",
  },
  {
    value: "yi",
    title: "Yiddish",
  },
  {
    value: "yo",
    title: "Yoruba",
  },
  {
    value: "za",
    title: "Zhuang, Chuang",
  },
];

export default languagesList;
