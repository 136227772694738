import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: (returnValue: boolean) => void;
  title: string;
  text: string;
};

function ConfirmationDialog({
  open,
  onClose,
  title,
  text,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography sx={{ pt: 1 }}>{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{text}</Typography>
        </DialogContentText>
        <Box sx={{ pt: 2, textAlign: "right" }}>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px" }}
            color="error"
            onClick={() => onClose(false)}
          >
            {t("projects.cancel")}
          </Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", ml: 2 }}
            onClick={() => onClose(true)}
          >
            {t("projects.confirm")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;
