import { useLoadingSpinnerContext } from "./LoadingSpinnerServiceProvider";

export default function LoadingSpinnerService() {
  const [loading, setLoading] = useLoadingSpinnerContext();
  const initCounter = 0;
  const disabledAll = false;
  return {
    loadingBlocker: disabledAll,
    counter: initCounter,
    addRequest() {
      setLoading(this.counter + 1);
    },
    addResponse() {
      setLoading(this.counter - 1);
    },
    getCounter() {
      return loading;
    },
    setLoadingBlocker(val: boolean) {
      this.loadingBlocker = val;
    },
  };
}
