import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TranslationKey } from "../../models/Translation";
import useDebounce from "../../utils/hooks";

type NewTranslationCardProps = {
  i: number;
  selectedNew: TranslationKey[];
  newKey: TranslationKey;
  allSelected: boolean;
  toggleSelectedNew: (i: number) => (e) => void;
  newProjectLanguageKeys: TranslationKey[];
  setNewProjectLanguageKeys: Dispatch<SetStateAction<TranslationKey[]>>;
  handleDraftSave: (
    keys: TranslationKey[],
    newState?: TranslationKey[],
    newKey?: boolean
  ) => void;
  handleDeletePending: (index: number) => void;
};

function NewTranslationCard({
  i,
  selectedNew,
  newKey,
  allSelected,
  toggleSelectedNew,
  newProjectLanguageKeys,
  setNewProjectLanguageKeys,
  handleDraftSave,
  handleDeletePending,
}: NewTranslationCardProps) {
  const [key, setKey] = useState(newKey.keyId || "");
  const [draft, setDraft] = useState(newKey.draft || "");

  const [keyDebounceVal, setKeyDebounceVal] = useState("");
  const [draftDebounceVal, setDraftDebounceVal] = useState("");

  const keyDebounceValue = useDebounce(key, 500);
  const draftDebounceValue = useDebounce(draft, 500);

  useEffect(() => {
    const newState = newProjectLanguageKeys.map((obj, index) => {
      if (i === index) {
        return { ...obj, draft };
      }

      return obj;
    });

    setNewProjectLanguageKeys(newState);
    setDraftDebounceVal(draft);
  }, [draftDebounceValue]);

  useEffect(() => {
    const newState = newProjectLanguageKeys.map((obj, index) => {
      if (i === index) {
        return { ...obj, keyId: key };
      }

      return obj;
    });

    setNewProjectLanguageKeys(newState);
    setKeyDebounceVal(key);
  }, [keyDebounceValue]);

  const handleDraftChange = (e) => {
    setDraft(e.target.value);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
        mb: 2,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5.5}>
            <Box sx={{ display: "inline-flex", width: "100%" }}>
              <Checkbox
                checked={
                  selectedNew.findIndex(
                    (x) => x.keyId === newKey.keyId && x.draft === newKey.draft
                  ) !== -1 || allSelected
                }
                onChange={toggleSelectedNew(i)}
                sx={{ mr: 1 }}
              />
              <TextField
                fullWidth
                variant="standard"
                label={t("translations.key")}
                value={key}
                onChange={handleKeyChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <TextField
              fullWidth
              multiline
              variant="standard"
              label={t("translations.value")}
              value={draft}
              onChange={handleDraftChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={0.5} sx={{ ml: -0.5, mr: 0.5, textAlign: "center" }}>
            <Button
              variant="contained"
              disabled={!key || !draft}
              sx={{
                // borderRadius: "25px",
                minWidth: "35px",
                maxWidth: "35px",
                maxHeight: "35px",
                width: "35px",
                height: "35px",
                mt: 1,
              }}
              onClick={() => {
                const newState = newProjectLanguageKeys.map((obj, index) => {
                  if (i === index) {
                    return { ...obj, keyId: key, draft };
                  }

                  return obj;
                });

                handleDraftSave(
                  [{ keyId: key, draft, published: null }],
                  newState,
                  true
                );
              }}
            >
              <SaveIcon />
            </Button>
          </Grid>
          <Grid
            item
            xs={0.5}
            sx={{
              ml: -0.5,
              mr: 0.5,
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{
                p: 0,
                minWidth: "35px",
                maxWidth: "35px",
                maxHeight: "35px",
                width: "35px",
                height: "35px",
                mt: 1,
              }}
              onClick={() => handleDeletePending(i)}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default NewTranslationCard;
