/* eslint-disable no-underscore-dangle */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UsersManagementDialog from "../../../components/UsersManagementDialog/UsersManagementDialog";
import RowMenu from "../../../components/UsersRowMenu/RowMenu";
import { ApiKey, GetApiKey } from "../../../models/Api";
import { ProjectLanguage } from "../../../models/Language";
import { Project, ProjectUser } from "../../../models/Project";
import { deleteData, get, put } from "../../../services/Api/ApiFunctions";
import useAuth from "../../../services/Auth/AuthService";
import { mapRoles } from "../../../utils/helpers";
import storageType from "../../../utils/storageService";
import ProjectsApiKeysCreationDialog from "./ProjectsApiKeysCreationDialog/ProjectsApiKeysCreationDialog";

interface FormValues {
  name: string;
  baseLanguageCode: string;
}

function ProjectsSettings() {
  const { id } = useParams();
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [project, setProject] = useState<Project>();
  const [update, setUpdate] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [projectLanguages, setProjectLanguages] = useState<ProjectLanguage[]>(
    []
  );
  const [users, setUsers] = useState<ProjectUser[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<FormValues>({
    mode: "all",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [cognitoEmail, setCognitoEmail] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [integrations, setIntegrations] = useState<ApiKey[]>([]);
  const [userRole, setUserRole] = useState("");
  const [areApisEnabled, setAreApisEnabled] = useState(false);
  const [isUserManagementEnabled, setIsUserManagementEnabled] = useState(false);
  const [apiDialogOpen, setApiDialogOpen] = useState(false);

  useEffect(() => {
    auth
      .getUser()
      .then((res) => {
        if (res) {
          setUserRole(res.role);
        }
      })
      .catch(() => {
        toast.error(t("errors.generic-error"));
      });
    if (
      storageType(
        process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
      ).getItem("method") === "third-party"
    ) {
      auth.oAuthAttributes().then((res) => {
        if (res && res.email) {
          setCognitoEmail(res.email || "");
        }
      });
    } else {
      auth.getAttributes().then((res) => {
        if (res) {
          const emailAttribute = res
            .find((x) => x.Name === "email")
            ?.getValue();
          setCognitoEmail(emailAttribute || "");
        }
      });
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isValid) {
      const body = { code: data.baseLanguageCode };
      put<{ code: string }>(`/projects/${project?._id}/base-language`, body)
        .then(() => {
          toast.success(t("settings.language-change-successful"));
        })
        .catch(() => toast.error(t("settings.language-change-error")));
    }
  };

  useEffect(() => {
    if (id && update) {
      get<Project>(`/projects/${id}`).then((res) => {
        get<GetApiKey>(`/projects/${id}/api-keys`)
          .then((response) => {
            setProject(res.data);
            setAreApisEnabled(res.data.areApisEnabled);
            setIsUserManagementEnabled(res.data.isUserManagementEnabled);
            setProjectName(res.data.name);
            setProjectLanguages(res.data.languages);
            setUsers(res.data.users);
            setIntegrations(response.data.apiKeys);
            setUpdate(false);
          })
          .catch(() => {
            setUpdate(false);
            toast.error(t("errors.generic-error"));
          });
      });
    }
  }, [id, update]);

  const handleDialogClose = (returnValue: string) => {
    setOpenDialog(false);
    setApiDialogOpen(false);
    if (returnValue) {
      setUpdate(true);
    }
  };

  const handleSuperAdminSettings = () => {
    if (project) {
      const body = {
        areApisEnabled,
        isUserManagementEnabled,
      };
      put(`/projects/${id}/super-admin-settings`, body)
        .then(() => {
          toast.success(t("settings.project-update-successful"));
        })
        .catch(() => {
          toast.error(t("settings.project-update-error"));
        });
    }
  };

  const handleApiKeyCopy = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey);
    toast.success(t("settings.api-key-copied"));
  };

  const handleDeleteApiKey = (apiKeyId: string) => {
    if (apiKeyId) {
      deleteData(`/projects/${id}/api-keys/${apiKeyId}`)
        .then(() => {
          toast.success(t("settings.key-deletion-successful"));
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("settings.key-deletion-error"));
        });
    }
  };

  return (
    <Box sx={{ width: "95%", margin: "6rem auto" }} className="layoutMinHeight">
      <UsersManagementDialog
        open={openDialog}
        onClose={handleDialogClose}
        userId={userId}
        type="pr"
      />
      <ProjectsApiKeysCreationDialog
        open={apiDialogOpen}
        onClose={handleDialogClose}
        id={id || ""}
      />
      <Box sx={{ display: "inline-flex" }}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "25px",
            backgroundColor: "white",
            minWidth: "40px",
            width: "40px",
            height: "40px",
            mr: 1,
          }}
          onClick={() => navigate("/projects")}
        >
          <ChevronLeftIcon />
        </Button>
        <Typography variant="h5" sx={{ mb: 5, mt: 0.75 }}>
          {project ? project.name : t("languages.project")} -&gt;{" "}
          {t("settings.settings")}
        </Typography>
      </Box>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
          mb: 2,
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {project && (
              <>
                <TextField
                  variant="filled"
                  label={`${t("projects.name")}`}
                  value={projectName}
                  {...register("name")}
                  helperText={
                    errors.name?.type === "required"
                      ? t("errors.required-field")
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{ mr: 2 }}
                />
                <FormControl>
                  <InputLabel className="MuiSelect-label">
                    {t("projects.base-language")}
                  </InputLabel>
                  <Controller
                    name="baseLanguageCode"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={projectLanguages[0].code}
                    render={({
                      field: { name, value, onChange, onBlur, ref },
                    }) => (
                      <Select
                        ref={ref}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        onChange={(event) => onChange(event as ChangeEvent)}
                        variant="filled"
                        label={`${t("projects.language")}`}
                        sx={{ width: "350px", mr: 2 }}
                      >
                        {projectLanguages.map((language) => (
                          <MenuItem value={language.code} key={language.code}>
                            {language.code}{" "}
                            {language.label ? `(${language.label})` : ""}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.baseLanguageCode?.type === "required" ? (
                    <FormHelperText>
                      {t("errors.required-field")}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <Button
                  type="submit"
                  disabled={!isValid}
                  variant="contained"
                  sx={{ borderRadius: "25px", mt: 1 }}
                >
                  {t("settings.update")}
                </Button>
              </>
            )}
          </form>
        </CardContent>
      </Card>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
          mb: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
            {t("settings.users")}
          </Typography>
          <Grid container>
            {users &&
              users.map((user) => (
                <Fragment key={user.userInfo._id}>
                  <Grid item xs={4} sx={{ mt: 1 }}>
                    {user.userInfo.firstName && user.userInfo.lastName ? (
                      <Typography>
                        {user.userInfo.firstName} {user.userInfo.lastName} (
                        {user.userInfo.email})
                      </Typography>
                    ) : (
                      <Typography>{user.userInfo.email}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 1 }}>
                    <Typography>{mapRoles(user.role, t)}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ mb: 1, textAlign: "right" }}>
                    <RowMenu
                      projectId={project?._id || ""}
                      projectUser={user}
                      cognitoEmail={cognitoEmail}
                      setOpenDialog={setOpenDialog}
                      setUserId={setUserId}
                      type="pr"
                      setUpdated={setUpdate}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
          </Grid>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", mt: 1, float: "right", mb: 2 }}
            onClick={() => {
              setUserId("");
              setOpenDialog(true);
            }}
          >
            {t("settings.add-user")}
          </Button>
        </CardContent>
      </Card>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
          mb: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
            {t("settings.integrations")}
          </Typography>
          <Grid container>
            {integrations &&
              integrations.length !== 0 &&
              integrations.map((integration) => (
                <Fragment key={integration._id}>
                  <Grid item xs={6} sx={{ mt: 1 }}>
                    <Typography>{integration.keyLabel}</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ mb: 1, textAlign: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ mr: 1, borderRadius: "25px" }}
                      onClick={() => handleApiKeyCopy(integration.value)}
                    >
                      <ContentCopyIcon />
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "25px" }}
                      onClick={() => handleDeleteApiKey(integration._id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
            {integrations && integrations.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {t("settings.no-api-keys")}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", mt: 1, float: "right", mb: 2 }}
            onClick={() => setApiDialogOpen(true)}
          >
            {t("settings.create-api-key")}
          </Button>
        </CardContent>
      </Card>
      {userRole === "sa" && (
        <Card
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
            mb: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
              {t("settings.options")}
            </Typography>
            <Box sx={{ display: "grid" }}>
              <Box sx={{ display: "inline-flex" }}>
                <Checkbox
                  checked={areApisEnabled}
                  value={areApisEnabled}
                  sx={{ mr: 1 }}
                  onChange={() => setAreApisEnabled(!areApisEnabled)}
                />
                <Typography sx={{ mt: 1.25 }}>
                  {t("settings.enable-api")}
                </Typography>
              </Box>
              <Box sx={{ display: "inline-flex" }}>
                <Checkbox
                  checked={isUserManagementEnabled}
                  value={isUserManagementEnabled}
                  sx={{ mr: 1 }}
                  onChange={() =>
                    setIsUserManagementEnabled(!isUserManagementEnabled)
                  }
                />
                <Typography sx={{ mt: 1.25 }}>
                  {t("settings.enable-users-management")}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ borderRadius: "25px", mt: 1, float: "right", mb: 2 }}
              onClick={handleSuperAdminSettings}
            >
              {t("settings.save")}
            </Button>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

export default ProjectsSettings;
