import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestInterceptor from "../services/Api/RequestInterceptor";
import ResponseInterceptor from "../services/Api/ResponseInterceptor";
import createTheme from "../theme";
import "./App.css";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <ThemeProvider theme={createTheme()}>
      <RequestInterceptor />
      <ResponseInterceptor />
      <ToastContainer theme="colored" autoClose={3000} />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
