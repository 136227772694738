import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../../utils/helpers";
import storageType from "../../utils/storageService";
import useAuth from "../Auth/AuthService";
import useLoadingSpinnerContext from "../LoadingSpinner/LoadingSpinnerService";

export default function RequestInterceptor() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner = useLoadingSpinnerContext();
  const auth = useAuth();

  async function getToken(): Promise<string> {
    try {
      if (
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).getItem("method") === "third-party"
      ) {
        if (
          isTokenExpired(
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : "local"
            ).getItem(`${process.env.REACT_APP_NAME}-token`)
          )
        ) {
          const token = await auth.oAuthTokenRefresh();
          if (token) {
            storageType(
              process.env.REACT_APP_STORAGE
                ? process.env.REACT_APP_STORAGE
                : "local"
            ).setItem(`${process.env.REACT_APP_NAME}-token`, token);
            return token;
          }
          return "";
        }
        return (
          storageType(
            process.env.REACT_APP_STORAGE
              ? process.env.REACT_APP_STORAGE
              : "local"
          ).getItem(`${process.env.REACT_APP_NAME}-token`) || ""
        );
      }
      const session = await Auth.currentSession();
      if (session) {
        const token = session.getIdToken().getJwtToken();
        if (token) {
          storageType(
            process.env.REACT_APP_STORAGE
              ? process.env.REACT_APP_STORAGE
              : "local"
          ).setItem(`${process.env.REACT_APP_NAME}-token`, token);
          return token;
        }
        return "";
      }
      return "";
    } catch {
      storageType(
        process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
      ).removeItem("zero12-localization-platform-web-token");
      window.location.href = "/login";
      return "";
    }
  }

  const initializeInterceptor = () => {
    setIsActive(true);
    return axios.interceptors.request.use(
      async (request) => {
        if (
          !request.url?.includes("dev-translation-platform") &&
          !request.url?.includes("prod-translation-platform")
        ) {
          loadingSpinner.addRequest();
          if (request && request.headers) {
            request.headers.Authorization = `Bearer ${await getToken()}`;
          }
        }
        if (request.headers.Authorization === "Bearer ") {
          storageType(process.env.REACT_APP_STORAGE ?? "local").removeItem(
            "zero12-localization-platform-web-token"
          );
          storageType(process.env.REACT_APP_STORAGE ?? "local").removeItem(
            "zero12-localization-platform-web-access-token"
          );
          storageType(process.env.REACT_APP_STORAGE ?? "local").removeItem(
            "method"
          );
          storageType(process.env.REACT_APP_STORAGE ?? "local").removeItem(
            "zero12-localization-platform-web-refresh-token"
          );
          window.location.href = "/login";
        }
        return request;
      },
      (error) => {
        loadingSpinner.addResponse();
        Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
