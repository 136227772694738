import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ResetPasswordDialogProps {
  open: boolean;
  onClose: (returnValue: string) => void;
}

export interface FormValues {
  email: string;
}

function ResetPasswordDialog({ open, onClose }: ResetPasswordDialogProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({ mode: "all" });

  const handleErrorText = (field: string, type?: string): string => {
    if (type) {
      if (type === "required") {
        return t("errors.required-field");
      }
      if (type === "pattern" && field === "email") {
        return t("errors.invalid-email");
      }
      if (type === "pattern" && field === "password") {
        return t("errors.password-pattern");
      }
    }
    return "";
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography data-cy="resetPasswordDialogTitle" sx={{ pt: 1 }}>
              {t("reset-password.recover-password")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>{t("reset-password.recover-instructions")}</Typography>
        <form>
          <TextField
            data-cy="recoveryEmailField"
            fullWidth
            autoFocus
            label={t("reset-password.recover-email")}
            autoComplete="email"
            variant="filled"
            sx={{ my: 2 }}
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
            helperText={handleErrorText("email", errors.email?.type)}
          />
        </form>
        <Typography>{t("reset-password.spam-warning")}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          data-cy="cancelButton"
          variant="contained"
          color="error"
          sx={{
            borderRadius: "16px",
            p: "16px 24px",
            textTransform: "none",
            fontWeight: theme.typography.fontWeightMedium,
          }}
          onClick={() => onClose("closed")}
        >
          {t("reset-password.cancel")}
        </Button>
        <Button
          data-cy="sendRequestButton"
          disabled={!isValid}
          variant="contained"
          sx={{
            borderRadius: "16px",
            p: "16px 24px",
            textTransform: "none",
            fontWeight: theme.typography.fontWeightMedium,
          }}
          onClick={() => onClose(getValues("email"))}
        >
          {t("reset-password.send-request")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPasswordDialog;
