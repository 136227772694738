import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

type AuthProtectedRouteProps = {
  returnChildren: boolean;
  redirectTo: string;
  children: ReactElement;
};

export default function AuthProtectedRoute({
  children,
  returnChildren,
  redirectTo,
}: AuthProtectedRouteProps): ReactElement {
  if (returnChildren) {
    return children;
  }
  return <Navigate to={redirectTo} replace />;
}
