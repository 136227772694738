/* eslint-disable jsx-a11y/label-has-associated-control */
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { post } from "../../../../services/Api/ApiFunctions";
import useAuth from "../../../../services/Auth/AuthService";
import { splitLastOccurrence } from "../../../../utils/helpers";
import storageType from "../../../../utils/storageService";
import "./ProfileDialog.css";

type ProfileDialogProps = {
  open: boolean;
  onClose: (returnValue: string) => void;
};

interface FormValues {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

function ProfileDialog({ open, onClose }: ProfileDialogProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    mode: "all",
  });
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<CognitoUser>();

  useEffect(() => {
    if (open) {
      if (
        !storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).getItem("method")
      ) {
        const getCurrentUserFunc = async () => {
          try {
            const user = await auth.getCurrentUser();
            setCurrentUser(user);
          } catch (err) {
            console.log("err", err);
          }
        };
        getCurrentUserFunc();
      }
      auth
        .getUser()
        .then((res) => {
          if (res) {
            // eslint-disable-next-line no-underscore-dangle
            setId(res._id);
          }
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
      if (
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).getItem("method") === "third-party"
      ) {
        auth.oAuthAttributes().then((res) => {
          if (res) {
            if (res.given_name) {
              setName(
                res.given_name.includes(" ")
                  ? `, ${res.given_name.split(" ")[0]}`
                  : `, ${res.given_name}` || ""
              );
            } else if (res.email) {
              setEmail(`, ${res.email}` || "");
            }
          }
        });
      } else {
        auth.getAttributes().then((res) => {
          if (res) {
            const nameAttribute = res
              .find((x) => x.Name === "name")
              ?.getValue()
              .split(" ")[0];
            const emailAttribute = res
              .find((x) => x.Name === "email")
              ?.getValue();
            setName(`, ${nameAttribute}` || "");
            setEmail(`, ${emailAttribute}` || "");
          }
        });
      }
    }
  }, [open]);

  const handleChangeProfilePic = (e) => {
    if (id && e.target.files.length) {
      post(`/users/${id}/profile-pic-presigned`, {
        imageExtension: `.${splitLastOccurrence(
          e.target.files[0].name,
          "."
        ).toLocaleLowerCase()}`,
      })
        .then((res) => {
          console.log("res", res);
          toast.success(t("profile.pic-upload-successful"));
        })
        .catch(() => {
          toast.error(t("profile.pic-upload-error"));
        });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isValid && currentUser) {
      try {
        await auth.changePassword(
          currentUser,
          data.oldPassword,
          data.newPassword
        );
        toast.success(t("reset-password.password-changed"));
        onClose("closed");
      } catch (err) {
        toast.success(t("reset-password.error-change-password"));
        console.log("err", err);
      }
    }
  };

  const handleErrorText = (type?: string): string => {
    if (type) {
      if (type === "required") {
        return t("errors.required-field");
      }
      if (type === "pattern") {
        return t("errors.password-pattern");
      }
      if (type === "validate") {
        return t("errors.password-must-match");
      }
    }
    return "";
  };

  const selectDenomination = () => {
    if (!name.includes("undefined")) {
      return name;
    }
    if (!email.includes("undefined")) {
      return email;
    }
    return "";
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              id="projectsApiKeysCreationDialogTitle"
              data-cy="projectsApiKeysCreationDialogTitle"
              sx={{ pt: 1 }}
            >
              {t("profile.edit-profile")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div style={{ textAlign: "center", position: "relative" }}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="icon-button-file"
              type="file"
              onChange={handleChangeProfilePic}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <div className="text-overlay">
                  <p style={{ marginTop: "10rem", fontSize: "0.55em" }}>
                    {t("profile.edit-image")}
                  </p>
                </div>
                <Avatar
                  sx={{ width: "200px", height: "200px", fontSize: "5em" }}
                >
                  {selectDenomination().charAt(2)}
                </Avatar>
              </IconButton>
            </label>
          </div>
          {!storageType(
            process.env.REACT_APP_STORAGE
              ? process.env.REACT_APP_STORAGE
              : "local"
          ).getItem("method") && (
            <>
              <Typography variant="h5" sx={{ my: 2 }}>
                {t("profile.change-password")}
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label={`${t("profile.old-password")}`}
                {...register("oldPassword", {
                  required: true,
                })}
                helperText={
                  errors.oldPassword?.type === "required"
                    ? t("errors.required-field")
                    : ""
                }
                sx={{ mr: 2, mb: 2 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label={`${t("profile.new-password")}`}
                {...register("newPassword", {
                  required: true,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                })}
                helperText={handleErrorText(errors.newPassword?.type)}
                sx={{ mr: 2, mb: 2 }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label={`${t("profile.repeat-new-password")}`}
                {...register("repeatNewPassword", {
                  required: true,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                  validate: (val: string) => {
                    const { newPassword } = getValues();
                    return newPassword === val;
                  },
                })}
                helperText={handleErrorText(errors.repeatNewPassword?.type)}
                sx={{ mr: 2 }}
              />
            </>
          )}
        </DialogContent>
        {!storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).getItem("method") && (
          <DialogActions sx={{ p: 2 }}>
            <Button
              type="submit"
              data-cy="profileDialogButton"
              disabled={!isValid}
              variant="contained"
              sx={{ borderRadius: "25px" }}
            >
              {t("profile.change-password")}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
}

export default ProfileDialog;
