import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import UsersManagementDialog from "../../components/UsersManagementDialog/UsersManagementDialog";
import RowMenu from "../../components/UsersRowMenu/RowMenu";
import { PaginationInfo } from "../../models/Pagination";
import { GetUser, User } from "../../models/User";
import { get } from "../../services/Api/ApiFunctions";
import useAuth from "../../services/Auth/AuthService";
import { mapRoles } from "../../utils/helpers";
import storageType from "../../utils/storageService";

interface FormValues {
  fullName: string;
  role: string;
}

function UsersManagement() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [updated, setUpdated] = useState<boolean>(true);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    searchParams.get("page")
      ? parseFloat(searchParams.get("page") as string)
      : 0
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [cognitoEmail, setCognitoEmail] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const { register, handleSubmit, control, setValue } = useForm<FormValues>({
    mode: "all",
  });
  const [nameFilter, setNameFilter] = useState<string>(
    searchParams.get("fullName") ? (searchParams.get("fullName") as string) : ""
  );
  const [roleFilter, setRoleFilter] = useState<string>(
    searchParams.get("role") ? (searchParams.get("role") as string) : ""
  );

  useEffect(() => {
    if (
      storageType(
        process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
      ).getItem("method") === "third-party"
    ) {
      auth.oAuthAttributes().then((res) => {
        if (res && res.email) {
          setCognitoEmail(res.email || "");
        }
      });
    } else {
      auth.getAttributes().then((res) => {
        if (res) {
          const emailAttribute = res
            .find((x) => x.Name === "email")
            ?.getValue();
          setCognitoEmail(emailAttribute || "");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (updated) {
      get<GetUser>(
        `/users`,
        new URLSearchParams(
          `start=${
            10 * pageNumber
          }&getTotalCount=true&fullName=${nameFilter}&role=${roleFilter}`
        )
      )
        .then((res) => {
          setUsers(res.data.items);
          if (res.data.paginationInfo && !paginationInfo) {
            setPaginationInfo(res.data.paginationInfo);
            if (res.data.totalCount) {
              setTotalCount(res.data.totalCount);
            }
          }
          setUpdated(false);
        })
        .catch(() => toast.error(t("errors.generic-error")));
    }
  }, [updated]);

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPageNumber(value - 1);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("page", String(value - 1));
    setSearchParams(updatedSearchParams.toString());
    setSearchParams(new URLSearchParams(`page=${value - 1}`));
    setUpdated(true);
  };

  const handleDialogClose = (returnValue: string) => {
    setOpenDialog(false);
    if (returnValue) {
      setUpdated(true);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (
    data: FormValues,
    event
  ) => {
    event?.preventDefault();
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("fullName", nameFilter);
    updatedSearchParams.set("role", data.role);
    setRoleFilter(data.role);
    setSearchParams(updatedSearchParams.toString());
    setSearchParams(
      new URLSearchParams(`fullName=${nameFilter}&role=${roleFilter}`)
    );
    setPaginationInfo(undefined);
    setPageNumber(0);
    setUpdated(true);
  };

  return (
    <Box sx={{ width: "95%", margin: "6rem auto" }} className="layoutMinHeight">
      <UsersManagementDialog
        open={openDialog}
        onClose={handleDialogClose}
        userId={userId}
        type="pl"
      />
      <Box sx={{ display: "inline-flex" }}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "25px",
            backgroundColor: "white",
            minWidth: "40px",
            width: "40px",
            height: "40px",
            mr: 1,
          }}
          onClick={() => navigate("/projects")}
        >
          <ChevronLeftIcon />
        </Button>
        <Typography variant="h5" sx={{ mb: 5, mt: 0.75 }}>
          {t("users-management.users-management")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "inline-flex" }}>
            <Card
              sx={{
                mb: 5,
                width: "fit-content",
                borderRadius: "16px",
                mr: 2,
              }}
            >
              <CardContent sx={{ p: "0 !important" }}>
                <TextField
                  variant="filled"
                  label={`${t("users-management.name")}`}
                  {...register("fullName")}
                  data-cy="fullNameFilterField"
                  className="fullName-filter-field"
                  onChange={(event) => {
                    setNameFilter(event.target.value);
                  }}
                />
              </CardContent>
            </Card>
            <Card
              sx={{
                mb: 5,
                width: "fit-content",
                borderRadius: "16px",
              }}
            >
              <CardContent sx={{ p: "0 !important" }}>
                <Controller
                  name="role"
                  control={control}
                  defaultValue={roleFilter || ""}
                  render={({
                    field: { name, value, onChange, onBlur, ref },
                  }) => (
                    <FormControl>
                      <InputLabel className="MuiSelect-label">
                        {t("settings.role")}
                      </InputLabel>
                      <Select
                        ref={ref}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        onChange={(event) => onChange(event as ChangeEvent)}
                        variant="filled"
                        label={`${t("settings.role")}`}
                        sx={{ width: "229px" }}
                      >
                        <MenuItem value="sa">Super Admin</MenuItem>
                        <MenuItem value="su">{t("settings.user")}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ ml: { xs: 2, md: 0 } }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: "25px", mb: { xs: 1, md: 5 }, mr: 2 }}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setSearchParams("");
                setNameFilter("");
                setRoleFilter("");
                setValue("role", "");
                setValue("fullName", "");
                setPaginationInfo(undefined);
                setPageNumber(0);
                setUpdated(true);
              }}
              sx={{ borderRadius: "25px", mb: 5 }}
            >
              <RefreshIcon />
            </Button>
          </Box>
        </Box>
      </form>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
          mb: 2,
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
            {t("settings.users")}
          </Typography>
          <Grid container spacing={4}>
            {users &&
              users.map((user) => (
                // eslint-disable-next-line no-underscore-dangle
                <Fragment key={user._id}>
                  <Grid item xs={4} sx={{ mt: 1 }}>
                    {user.firstName && user.lastName ? (
                      <Typography>
                        {user.firstName}{" "}
                        {user.lastName !== user.firstName.split(" ")[1]
                          ? user.lastName
                          : ""}{" "}
                        ({user.email})
                      </Typography>
                    ) : (
                      <Typography>{user.email}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 1 }}>
                    <Typography>{mapRoles(user.role, t)}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ mb: 1, textAlign: "right" }}>
                    <RowMenu
                      projectId=""
                      user={user}
                      cognitoEmail={cognitoEmail}
                      setOpenDialog={setOpenDialog}
                      setUserId={setUserId}
                      type="pl"
                      setUpdated={setUpdated}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Divider />
                  </Grid>
                </Fragment>
              ))}
          </Grid>
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", mt: 1, float: "right", mb: 2 }}
            onClick={() => {
              setUserId("");
              setOpenDialog(true);
            }}
          >
            {t("settings.add-user")}
          </Button>
        </CardContent>
      </Card>
      {users && users.length !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={pageNumber + 1}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </Box>
  );
}

export default UsersManagement;
