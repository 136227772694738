import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";

interface LoadingSpinnerProps {
  children: ReactNode;
}

type LoadingContextType = [
  number,
  React.Dispatch<React.SetStateAction<number>>
];

const LoadingSpinnerContext = createContext<LoadingContextType | null>(null);

function LoadingSpinnerProvider({
  children,
}: LoadingSpinnerProps): ReactElement {
  const loadingState = useState<number>(0);

  return (
    <LoadingSpinnerContext.Provider value={loadingState}>
      {children}
    </LoadingSpinnerContext.Provider>
  );
}

function useLoadingSpinnerContext() {
  const context = useContext(LoadingSpinnerContext);
  if (context === null) {
    throw new Error(
      "Please wrap this function into the LoadingSpinnerProvider"
    );
  }
  return context;
}

export { LoadingSpinnerProvider, useLoadingSpinnerContext };
