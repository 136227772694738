import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Lottie from "lottie-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import forgetPasswordIllustration from "../../assets/animations/forget-password-animation.json";
import forgotPasswordIllustrationMobile from "../../assets/animations/forgot-password.json";
import useAuth from "../../services/Auth/AuthService";
import "./ResetPassword.css";

export interface FormValues {
  email: string;
  code: string;
  newPassword: string;
  confirmNewPassword: string;
}

function ResetPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({ mode: "all" });
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    try {
      await auth.submitResetPassword(data.email, data.code, data.newPassword);
      toast.success(t("reset-password.password-changed"));
      navigate("/login");
    } catch (err) {
      toast.success(t("reset-password.code-mismatch"));
    }
  };

  const handleErrorText = (field: string, type?: string): string => {
    if (type) {
      if (type === "required") {
        return t("errors.required-field");
      }
      if (type === "pattern" && field === "email") {
        return t("errors.invalid-email");
      }
      if (type === "pattern" && field === "password") {
        return t("errors.password-pattern");
      }
      if (type === "validate") {
        return t("errors.password-must-match");
      }
    }
    return "";
  };

  return (
    <Grid container>
      {!isMobile && (
        <Grid item xs={6}>
          <Lottie
            alt={t("alt.forgot-password-animation")}
            animationData={forgetPasswordIllustration}
            style={{
              width: "50vw",
              height: "99vh",
              backgroundColor: "#F3F7FD",
            }}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ alignSelf: "center", textAlign: "center" }}
      >
        {isMobile ? (
          <Grid item>
            <Lottie
              alt={t("alt.forgot-password-animation-mobile")}
              animationData={forgotPasswordIllustrationMobile}
              style={{
                width: "40%",
                margin: "auto",
              }}
            />
          </Grid>
        ) : null}
        <Grid container sx={{ placeContent: "center" }}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 64,
                width: 64,
                mb: 2,
              }}
              alt={t("alt.logo")}
              src="../logo.png"
            />
          </Grid>
          <Grid item sx={{ alignSelf: "center", ml: 1, mb: 2 }}>
            <Typography
              component="h1"
              variant="h1"
              className="text-gradient"
              sx={{ fontFamily: "LamaRounded" }}
            >
              trAPP
            </Typography>
          </Grid>
        </Grid>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          {t("reset-password.reset-password")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid item sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label={t("login.email")}
                autoComplete="email"
                value={decodeURIComponent(String(searchParams.get("email")))}
                variant="filled"
                sx={{ width: "65%" }}
                {...register("email", {
                  required: true,
                  value: decodeURIComponent(String(searchParams.get("email"))),
                  pattern:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                })}
                data-cy="emailField"
                helperText={handleErrorText("email", errors.email?.type)}
              />
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <TextField
                fullWidth
                autoFocus
                label={t("reset-password.reset-code")}
                variant="filled"
                sx={{ width: "65%" }}
                {...register("code", { required: true })}
                data-cy="codeField"
                helperText={
                  errors.code?.type === "required"
                    ? t("errors.required-field")
                    : ""
                }
              />
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label={t("login.new-password")}
                type="password"
                variant="filled"
                sx={{ width: "65%" }}
                helperText={handleErrorText(
                  "password",
                  errors.newPassword?.type
                )}
                {...register("newPassword", {
                  required: true,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                })}
                data-cy="newPasswordField"
              />
            </Grid>
            <Grid item sx={{ mb: 5 }}>
              <TextField
                fullWidth
                label={t("reset-password.confirm-new-password")}
                type="password"
                variant="filled"
                sx={{ width: "65%" }}
                helperText={handleErrorText(
                  "password",
                  errors.confirmNewPassword?.type
                )}
                {...register("confirmNewPassword", {
                  required: true,
                  pattern:
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/,
                  validate: (val: string) => {
                    const { newPassword } = getValues();
                    return newPassword === val;
                  },
                })}
                data-cy="confirmNewPasswordField"
              />
            </Grid>
            <Grid item sx={{ maxHeight: "56.5px" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "60%",
                  borderRadius: "16px",
                  p: "16px 24px",
                  textTransform: "none",
                  fontWeight: theme.typography.fontWeightMedium,
                }}
                disabled={!isValid}
              >
                {t("login.confirm-password-login")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
