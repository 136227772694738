// amplify-config.js.

// -- AWS AMPLIFY CONFIGURATION PARAMETERS --

// eslint-disable-next-line import/no-unresolved

// Uncomment this to test env vars
// console.log('env', process.env);

const AmplifyConfig = {
  // Existing Auth
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_REGION,

    // REQUIRED - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLID,

    // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_USERPOOLWEBCLIENTID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    oAuth: {
      grantType: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_GRANT_TYPE,
      oauthTokenUrl:
        process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_OAUTH_TOKEN_URL,
      redirectUrl: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_REDIRECT_URL,
      url: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_URL,
      domain: process.env.REACT_APP_AMPLIFY_CONFIG_AUTH_OAUTH_DOMAIN,
    },
  },
};

export default AmplifyConfig;
