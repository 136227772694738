/* eslint-disable no-underscore-dangle */
import DeleteIcon from "@mui/icons-material/Delete";
// import LockIcon from "@mui/icons-material/Lock";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Button, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ProjectUser } from "../../models/Project";
import { User } from "../../models/User";
import { deleteData } from "../../services/Api/ApiFunctions";

type RowMenuProps = {
  projectId: string;
  cognitoEmail: string;
  setOpenDialog: (state: boolean) => void;
  setUserId: (state: string) => void;
  type: "pl" | "pr";
  setUpdated: (state: boolean) => void;
  user?: User;
  projectUser?: ProjectUser;
};

function RowMenu({
  projectId,
  user,
  projectUser,
  cognitoEmail,
  setOpenDialog,
  setUserId,
  type,
  setUpdated,
}: RowMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUser = () => {
    if (user) {
      deleteData(`/users/${user._id}`)
        .then(() => {
          setUpdated(true);
          toast.success(t("users-management.user-deletion-successful"));
        })
        .catch(() => {
          toast.error(t("users-management.user-deletion-error"));
        });
    } else if (projectUser) {
      deleteData(`/users/${projectUser.userInfo._id}`)
        .then(() => {
          setUpdated(true);
          toast.success(t("users-management.user-deletion-successful"));
        })
        .catch(() => {
          toast.error(t("users-management.user-deletion-error"));
        });
    }
  };

  const handleRemoveUserFromProject = () => {
    if (user) {
      deleteData(`/projects/${projectId}/users/${user._id}`)
        .then(() => {
          setUpdated(true);
          toast.success(t("settings.user-remotion-successful"));
        })
        .catch(() => {
          toast.error(t("settings.user-remotion-error"));
        });
    } else if (projectUser) {
      deleteData(`/projects/${projectId}/users/${projectUser.userInfo._id}`)
        .then(() => {
          setUpdated(true);
          toast.success(t("settings.user-remotion-successful"));
        })
        .catch(() => {
          toast.error(t("settings.user-remotion-error"));
        });
    }
  };

  const checkUserId = () => {
    if (user) {
      return user._id;
    }
    if (projectUser) {
      return projectUser.userId;
    }
    return "";
  };

  const checkUserEmail = () => {
    if (user) {
      return cognitoEmail === user.email;
    }
    if (projectUser) {
      return cognitoEmail === projectUser.userInfo.email;
    }
    return false;
  };

  return (
    <>
      <Button
        disabled={checkUserEmail()}
        variant="contained"
        sx={{ borderRadius: "25px" }}
        id="more-button"
        aria-controls={open ? "more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="more-menu"
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {type === "pr" ? (
          <MenuItem
            onClick={() => {
              handleRemoveUserFromProject();
              handleClose();
            }}
            disableRipple
          >
            <PersonRemoveIcon sx={{ mr: 1 }} />
            {t("settings.remove-user-project")}
          </MenuItem>
        ) : null}
        {type === "pl" ? (
          <MenuItem
            onClick={() => {
              setUserId(checkUserId());
              setOpenDialog(true);
              handleClose();
            }}
            disableRipple
          >
            <ManageAccountsIcon sx={{ mr: 1 }} />
            {t("users-management.edit-user")}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setUserId(checkUserId());
              setOpenDialog(true);
              handleClose();
            }}
            disableRipple
          >
            <ManageAccountsIcon sx={{ mr: 1 }} />
            {t("settings.change-role")}
          </MenuItem>
        )}
        {type === "pl" && (
          <MenuItem
            onClick={() => {
              handleDeleteUser();
              handleClose();
            }}
            disableRipple
          >
            <DeleteIcon sx={{ mr: 1 }} />
            {t("users-management.delete-user")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

RowMenu.defaultProps = {
  user: undefined,
  projectUser: undefined,
};

export default RowMenu;
