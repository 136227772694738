/* eslint-disable no-underscore-dangle */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PostProjectUser, Project } from "../../models/Project";
import { PostUser, PutUser, User } from "../../models/User";
import { get, post, put } from "../../services/Api/ApiFunctions";

type UsersManagementDialogProps = {
  open: boolean;
  onClose: (returnValue: string) => void;
  userId: string;
  type: "pl" | "pr";
};

interface FormValues {
  email: string;
  role: "sa" | "su" | "ad" | "tr" | "";
  firstName: string;
  lastName: string;
}

function UsersManagementDialog({
  open,
  onClose,
  userId,
  type,
}: UsersManagementDialogProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [userEmail, setUserEmail] = useState<string>("");
  const [userRole, setUserRole] = useState<"sa" | "su" | "ad" | "tr" | "">("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm<FormValues>({ mode: "all" });
  const [keyId, setKeyId] = useState<string>(Math.random().toString());

  useEffect(() => {
    if (open) {
      setUserEmail("");
      setValue("email", "");
      setUserRole("");
      setValue("role", "");
      setFirstName("");
      setValue("firstName", "");
      setLastName("");
      setValue("lastName", "");
      if (userId) {
        get<User>(`/users/${userId}`)
          .then((res) => {
            const projectUser = res.data;
            if (type === "pr") {
              get<Project>(`/projects/${id}`)
                .then((r) => {
                  const userIndex = r.data.users.findIndex(
                    (x) => x.userId === projectUser._id
                  );
                  if (userIndex !== -1) {
                    projectUser.role = r.data.users[userIndex].role;
                    setUserEmail(projectUser.email);
                    setUserRole(projectUser.role);
                  }
                })
                .catch(() => {
                  toast.error(t("errors.generic-error"));
                });
            } else {
              setUserEmail(projectUser.email);
              setValue("email", projectUser.email);
              setUserRole(projectUser.role);
              setFirstName(projectUser.firstName);
              setValue("firstName", projectUser.firstName);
              setLastName(projectUser.lastName);
              setValue("lastName", projectUser.lastName);
            }
          })
          .catch(() => {
            toast.error(t("errors.generic-error"));
          });
      }
    }
  }, [open, userId]);

  useEffect(() => {
    if (userRole) {
      setKeyId(Math.random().toString());
      setValue("role", userRole, { shouldValidate: true });
    }
  }, [userRole]);

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isValid) {
      if (type === "pl") {
        if (userId) {
          const { email, ...body } = data;
          put<PutUser>(`/users/${userId}`, body)
            .then(() => {
              onClose("edited");
              toast.success(t("users-management.user-edit-successful"));
            })
            .catch(() => {
              toast.error(t("users-management.user-edit-error"));
            });
        } else {
          const { role, ...body } = data;
          post<PostUser>("/users", body)
            .then(() => {
              onClose("created");
              toast.success(t("users-management.user-creation-successful"));
            })
            .catch(() => {
              toast.error(t("users-management.user-creation-error"));
            });
        }
      } else if (type === "pr") {
        if (userId) {
          put<{ role: "ad" | "tr" | "su" | "sa" | "" }>(
            `/projects/${id}/users/${userId}`,
            {
              role: data.role,
            }
          )
            .then(() => {
              onClose("edited");
              toast.success(t("settings.role-change-successful"));
            })
            .catch(() => {
              toast.error(t("settings.role-change-error"));
            });
        } else {
          // eslint-disable-next-line no-shadow
          const { firstName, lastName, ...body } = data;
          post<PostProjectUser>(`/projects/${id}/users/${userId}`, body)
            .then(() => {
              onClose("created");
              toast.success(t("settings.user-add-successful"));
            })
            .catch(() => {
              toast.error(t("settings.user-add-error"));
            });
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              id="projectsUsersManagementDialogTitle"
              data-cy="projectsUsersManagementDialogTitle"
              sx={{ pt: 1 }}
            >
              {userId ? t("settings.edit-user") : t("settings.add-user")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            variant="filled"
            label={`${t("settings.email")}`}
            value={userEmail}
            {...register("email", {
              required: !userId,
            })}
            onChange={(e) => setUserEmail(e.target.value)}
            helperText={
              errors.email?.type === "required"
                ? t("errors.required-field")
                : ""
            }
            sx={{ mr: 2 }}
            InputProps={{
              readOnly: !!userId,
            }}
          />
          {type === "pl" && (
            <>
              <TextField
                variant="filled"
                label={`${t("users-management.name")}`}
                value={firstName}
                {...register("firstName", {
                  required: !userId && type === "pl",
                })}
                onChange={(e) => setFirstName(e.target.value)}
                helperText={
                  errors.firstName?.type === "required"
                    ? t("errors.required-field")
                    : ""
                }
                sx={{ mr: 2 }}
              />
              <TextField
                variant="filled"
                label={`${t("users-management.surname")}`}
                value={lastName}
                {...register("lastName", {
                  required: !userId && type === "pl",
                })}
                onChange={(e) => setLastName(e.target.value)}
                helperText={
                  errors.lastName?.type === "required"
                    ? t("errors.required-field")
                    : ""
                }
                sx={{ mr: 2 }}
              />
            </>
          )}
          {userId || type === "pr" ? (
            <Controller
              key={keyId}
              name="role"
              control={control}
              rules={{ required: true }}
              defaultValue={userRole}
              render={({ field: { name, value, onChange, onBlur, ref } }) => (
                <FormControl>
                  <InputLabel className="MuiSelect-label">
                    {t("settings.role")}
                  </InputLabel>
                  <Select
                    ref={ref}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    onChange={(event) => onChange(event as ChangeEvent)}
                    variant="filled"
                    label={`${t("settings.role")}`}
                    sx={{ width: "229px" }}
                  >
                    <MenuItem
                      value="ad"
                      sx={{ display: type === "pr" ? "auto" : "none" }}
                    >
                      Admin
                    </MenuItem>
                    <MenuItem
                      value="tr"
                      sx={{ display: type === "pr" ? "auto" : "none" }}
                    >
                      {t("settings.translator")}
                    </MenuItem>
                    <MenuItem
                      value="sa"
                      sx={{ display: type === "pl" ? "auto" : "none" }}
                    >
                      Super Admin
                    </MenuItem>
                    <MenuItem
                      value="su"
                      sx={{ display: type === "pl" ? "auto" : "none" }}
                    >
                      {t("settings.user")}
                    </MenuItem>
                  </Select>
                  {errors.role?.type === "required" ? (
                    <FormHelperText>
                      {t("errors.required-field")}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
          ) : null}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            type="submit"
            data-cy="projectsUsersManagementDialogButton"
            disabled={!isValid}
            variant="contained"
            sx={{ borderRadius: "25px" }}
          >
            {userId ? t("settings.edit-user") : t("settings.add-user")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UsersManagementDialog;
