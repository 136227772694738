import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import LanguagesList from "../pages/Languages/LanguagesList/LanguagesList";
import Login from "../pages/Login/Login";
import ProjectsList from "../pages/Projects/ProjectsList/ProjectsList";
import ProjectsSettings from "../pages/Projects/ProjectsSettings/ProjectsSettings";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import TranslationsList from "../pages/Translations/TranslationsList";
import UsersManagement from "../pages/Users/UsersManagement";
import AuthProtectedRoute from "../services/Auth/AuthProtectedRoutes";
import useAuth from "../services/Auth/AuthService";
import LoadingSpinner from "../services/LoadingSpinner/LoadingSpinner";
import useLoadingSpinnerContext from "../services/LoadingSpinner/LoadingSpinnerService";

function AppRoutes() {
  const loadingSpinner = useLoadingSpinnerContext();
  const auth = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        {/* ROOT PATH */}
        <Route
          path="/"
          element={
            <AuthProtectedRoute
              redirectTo="/projects"
              returnChildren={!auth.getIsLoggedIn()}
            >
              <Navigate to="/login" replace />
            </AuthProtectedRoute>
          }
        />

        {/* LOGIN PATH */}
        <Route
          path="/login"
          element={
            <AuthProtectedRoute
              redirectTo="/projects"
              returnChildren={!auth.getIsLoggedIn()}
            >
              <Login />
            </AuthProtectedRoute>
          }
        />

        {/* RESET PASSWORD PATH */}
        <Route
          path="/reset-password"
          element={
            <AuthProtectedRoute
              redirectTo="/projects"
              returnChildren={!auth.getIsLoggedIn()}
            >
              <ResetPassword />
            </AuthProtectedRoute>
          }
        />

        {/* MAIN PATHS */}
        <Route
          element={
            <AuthProtectedRoute
              redirectTo="/login"
              returnChildren={auth.getIsLoggedIn()}
            >
              <>
                {loadingSpinner.getCounter() > 0 ? <LoadingSpinner /> : null}
                <Layout />
              </>
            </AuthProtectedRoute>
          }
        >
          <Route
            path="/projects"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                <ProjectsList />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/projects/:id"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                <LanguagesList />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/projects/:id/translations/:lang"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                <TranslationsList />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/projects/:id/settings"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn()}
              >
                <ProjectsSettings />
              </AuthProtectedRoute>
            }
          />
          <Route
            path="/users-management"
            element={
              <AuthProtectedRoute
                redirectTo="/login"
                returnChildren={auth.getIsLoggedIn() && auth.getIsSuperAdmin()}
              >
                <UsersManagement />
              </AuthProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
