export default function storageType(storage: string): Storage {
  switch (storage) {
    case "local":
      return localStorage;
    case "session":
      return sessionStorage;
    default:
      // use sessionStorage if not selected anything
      return sessionStorage;
  }
}
