import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Footer.css";

function Footer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "inherit",
        height: "150px",
        mt: "auto",
        overflow: "hidden",
        px: 2,
        pt: 5,
        backgroundColor: "#0068C1",
        color: "secondary.main",
      }}
    >
      <Container>
        <Grid container>
          {!isMobile && (
            <Grid item xs={12} md={3} mb={{ xs: 4, md: 0 }}>
              <Stack direction="row" alignItems="center" sx={{ ml: 2.5 }}>
                <Box
                  component="img"
                  sx={{
                    height: 64,
                    width: 72,
                  }}
                  alt={t("alt.logo")}
                  // eslint-disable-next-line global-require
                  src={require("../../../assets/images/logo-white.png")}
                />
                <Typography
                  sx={{ fontSize: { xs: 34, md: 40 }, ml: 2, color: "#fff" }}
                  fontFamily="LamaRounded"
                >
                  trAPP
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            mb={{ xs: 4, md: 0 }}
            mt={{ xs: -2, md: 0 }}
          >
            <Typography mb={3}>
              <a className="email-link" href="mailto:support@zero12.it">
                support@zero12.it
              </a>
            </Typography>
            <Typography color="secondary.main">Via Salboro 22/B,</Typography>
            <Typography color="secondary.main">35124 Padova</Typography>
            <Typography color="secondary.main">
              {t("footer.vat")} 04631710284
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={3}
            mb={{ xs: 4, md: 0 }}
            mt={{ xs: -2, md: 0 }}
          >
            <Grid container>
              <Grid item xs={6} md={12} lg={6}>
                <Stack>
                  <Typography
                    color="secondary.main"
                    component="a"
                    target="_BLANK"
                    href="https://www.zero12.it/contatti/"
                    sx={{ pb: 1, width: "100%", textDecoration: "underline" }}
                  >
                    {t("footer.contacts")}
                  </Typography>
                  <Typography
                    color="secondary.main"
                    sx={{
                      pb: 1,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.privacy")}
                  </Typography>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      color="secondary.main"
                      sx={{
                        pb: 1,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {t("footer.terms")}
                    </Typography>
                  </Stack>
                  {isMobile && (
                    <Typography color="secondary.main" fontSize={10}>
                      &copy; 2023 - zero12 srl -{" "}
                      {t("footer.all-rights-reserved")}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} mb={{ xs: 4, md: 0 }}>
            <Grid container direction="column">
              {!isMobile && (
                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs={2} sm={1} md={4} lg={3}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/zero12srl/"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.ig-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/ig.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={1} md={4} lg={3}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/zero12-s-r-l-/"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.li-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/li.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={1} md={4} lg={3}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/Zero12Srl"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.fb-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/fb.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item sx={{ mt: 6 }}>
                <Typography color="secondary.main" fontSize={14}>
                  &copy; 2023 - zero12 srl - {t("footer.all-rights-reserved")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} my={{ xs: 4, md: 8 }}></Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
