import { Checklist, Help, Settings } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../services/Auth/AuthService";
import storageType from "../../../utils/storageService";
import "./Header.css";
import ProfileDialog from "./ProfileDialog/ProfileDialog";

function Header() {
  const theme = useTheme();
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  // top-right logged user name and/or email
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  useEffect(() => {
    if (
      storageType(
        process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
      ).getItem("method") === "third-party"
    ) {
      auth.oAuthAttributes().then((res) => {
        if (res) {
          if (res.given_name) {
            setName(
              res.given_name.includes(" ")
                ? `, ${res.given_name.split(" ")[0]}`
                : `, ${res.given_name}` || ""
            );
          } else if (res.email) {
            setEmail(`, ${res.email}` || "");
          }
        }
      });
    } else {
      auth.getAttributes().then((res) => {
        if (res) {
          const nameAttribute = res
            .find((x) => x.Name === "name")
            ?.getValue()
            .split(" ")[0];
          const emailAttribute = res
            .find((x) => x.Name === "email")
            ?.getValue();
          setName(`, ${nameAttribute}` || "");
          setEmail(`, ${emailAttribute}` || "");
        }
      });
    }
  }, []);

  const settings = [t("navbar.profile"), t("navbar.logout")];

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async (e) => {
    if (e.target.textContent === t("navbar.logout")) {
      if (
        storageType(
          process.env.REACT_APP_STORAGE
            ? process.env.REACT_APP_STORAGE
            : "local"
        ).getItem("method") === "third-party"
      ) {
        auth.oAuthLogout();
      } else {
        await auth.logout();
      }
      navigate("/login");
    } else if (e.target.textContent === t("navbar.profile")) {
      setOpen(true);
    }
    setAnchorElUser(null);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const selectDenomination = () => {
    if (name && !name.includes("undefined")) {
      return name;
    }
    if (email && !email.includes("undefined")) {
      return email;
    }
    return "";
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 25px 0px rgba(45, 69, 95, 0.1)",
      }}
    >
      <ProfileDialog open={open} onClose={handleCloseDialog} />
      <Container maxWidth={false} sx={{ maxWidth: "100%" }}>
        <Toolbar disableGutters sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              minWidth: "-webkit-min-content",
              maxWidth: "30%",
              pt: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 36,
                width: 42,
                pr: 1,
                pt: 0.25,
                cursor: "pointer",
              }}
              alt={t("alt.logo")}
              src="../../../logo.png"
              onClick={() => navigate("/projects")}
            />
            <Typography
              variant="h4"
              component="div"
              className="text-gradient"
              sx={{
                fontFamily: "LamaRounded",
                alignSelf: "center",
                cursor: "pointer",
                py: 1,
              }}
              onClick={() => navigate("/projects")}
            >
              trAPP
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: "flex" }}>
            <Grid container gap={2} sx={{ justifyContent: "flex-end" }}>
              <Grid item sx={{ alignSelf: "center" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "secondary.contrastText" }}
                >
                  {t("navbar.hello")}
                  {selectDenomination()}
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ bgcolor: theme.palette.primary.main }}
                    alt={t("alt.avatar")}
                  >
                    {selectDenomination().charAt(2)}
                  </Avatar>
                </IconButton>
              </Grid>
            </Grid>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={(e) => handleCloseUserMenu(e)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
