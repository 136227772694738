import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Language } from "../../../models/Language";
import languagesList from "../../../utils/constants";
import { compare } from "../../../utils/helpers";
import "./LanguagesManagementDialog.css";

type LanguagesManagementDialogProps = {
  projectLanguages: Language[];
  open: boolean;
  onClose: (
    type: "closed" | "add" | "remove",
    returnValue?: { code: string; label?: string }
  ) => void;
};

function Row(props: {
  row: Language;
  filteredLanguages: Language[];
  projectLanguages: Language[];
  onClose: (
    type: "closed" | "add" | "remove",
    returnValue?: { code: string; label?: string }
  ) => void;
}) {
  const { row, filteredLanguages, projectLanguages, onClose } = props;
  const [open, setOpen] = useState(false);
  const [customDerivation, setCustomDerivation] = useState("");
  const [description, setDescription] = useState("");

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Chip label={row.value} />
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell align="right">
          {filteredLanguages.filter((x) => x.value.includes(row.value))
            .length === 1 && (
            <Button
              variant="contained"
              color={
                projectLanguages.findIndex(
                  (x) => x.value === `${row.value}-**`
                ) !== -1
                  ? "error"
                  : "primary"
              }
              sx={{ borderRadius: "16px" }}
              onClick={() => {
                if (
                  projectLanguages.findIndex(
                    (x) => x.value === `${row.value}-**`
                  ) !== -1
                ) {
                  onClose("remove", {
                    code: `${row.value}-**`,
                  });
                } else {
                  onClose("add", {
                    code: `${row.value}-**`,
                    label: row.title,
                  });
                }
              }}
            >
              {projectLanguages.findIndex(
                (x) => x.value === `${row.value}-**`
              ) !== -1
                ? t("languages.remove")
                : t("languages.add")}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="languages">
                <TableBody>
                  {filteredLanguages.map((language) => {
                    if (
                      language.value.includes("-") &&
                      row.value === language.value.split("-")[0]
                    ) {
                      return (
                        <TableRow key={language.value}>
                          <TableCell
                            width="8.5%"
                            sx={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              pr: 0,
                            }}
                          />
                          <TableCell
                            width="9.6%"
                            sx={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                              pl: 0,
                            }}
                          >
                            <Chip label={language.value} />
                          </TableCell>
                          <TableCell
                            width="69.6%"
                            sx={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            {language.title}
                          </TableCell>
                          <TableCell
                            align="right"
                            width="12.3%"
                            sx={{
                              borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            }}
                          >
                            <Button
                              variant="contained"
                              color={
                                projectLanguages.findIndex(
                                  (x) => x.value === language.value
                                ) !== -1
                                  ? "error"
                                  : "primary"
                              }
                              sx={{ borderRadius: "16px" }}
                              onClick={() => {
                                if (
                                  projectLanguages.findIndex(
                                    (x) => x.value === language.value
                                  ) !== -1
                                ) {
                                  onClose("remove", { code: language.value });
                                } else {
                                  onClose("add", {
                                    code: language.value,
                                    label: language.title,
                                  });
                                }
                              }}
                            >
                              {projectLanguages.findIndex(
                                (x) => x.value === language.value
                              ) !== -1
                                ? t("languages.remove")
                                : t("languages.add")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                  <TableRow>
                    <TableCell width="12.5%" sx={{ pr: 0 }}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label={t("languages.language")}
                        value={row.value}
                        disabled
                        InputLabelProps={{ shrink: true }}
                      />
                    </TableCell>
                    <TableCell width="12.5%" sx={{ pl: 0 }}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label={t("languages.derivation")}
                        onChange={(event) =>
                          setCustomDerivation(event.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell width="65%">
                      <TextField
                        fullWidth
                        variant="standard"
                        label={t("languages.description")}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={!description || !customDerivation}
                        variant="contained"
                        sx={{ borderRadius: "16px" }}
                        onClick={() =>
                          onClose("add", {
                            code: `${row.value}-${customDerivation}`,
                            label: description,
                          })
                        }
                      >
                        {t("languages.add")}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function LanguagesManagementDialog({
  projectLanguages,
  open,
  onClose,
}: LanguagesManagementDialogProps) {
  const languages = languagesList.sort(compare);
  const [filteredList, setFilteredList] = useState<Language[]>(languages);
  const [injected, setInjected] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (open) {
      setFilteredList(languages);
      setInitialized(true);
    } else {
      setInjected(false);
      setInitialized(false);
    }
  }, [open]);

  useEffect(() => {
    if (open && initialized && !injected && filteredList && projectLanguages) {
      const result: Language[] = [];
      for (let i = 0; i < filteredList.length; i += 1) {
        if (result.findIndex((x) => x.value === filteredList[i].value) === -1) {
          result.push(filteredList[i]);
        }
      }
      for (let i = 0; i < projectLanguages.length; i += 1) {
        if (
          result.findIndex((x) => x.value === projectLanguages[i].value) === -1
        ) {
          result.push(projectLanguages[i]);
        }
      }
      setFilteredList(result);
      setInjected(true);
    }
  }, [filteredList, projectLanguages, open, injected, initialized]);

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;
    // Create copy of item list
    let updatedList = [...languages];
    // Include all elements which includes the search query
    updatedList = updatedList.filter(
      (item) =>
        item.value.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (item.lang &&
          item.lang.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
    // Trigger render with updated values
    setFilteredList(updatedList);
    setInjected(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose("closed")}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Typography data-cy="languagesManagementDialogTitle" sx={{ pt: 1 }}>
              {t("languages.languages-management")}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-cy="closeButton"
              sx={{ float: "right" }}
              onClick={() => onClose("closed")}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <TextField
          variant="standard"
          label={t("languages.search")}
          sx={{ px: "24px", width: "94.7%" }}
          InputLabelProps={{ style: { paddingLeft: "32px" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={filterBySearch}
        />
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t("languages.iso")}</TableCell>
                <TableCell>{t("languages.name")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((language) => {
                if (!language.value.includes("-")) {
                  return (
                    <Row
                      key={language.value}
                      row={language}
                      filteredLanguages={filteredList}
                      projectLanguages={projectLanguages}
                      onClose={onClose}
                    />
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

export default LanguagesManagementDialog;
