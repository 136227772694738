import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import { User } from "../../models/User";
import { UserAttributes } from "../../models/UserAttributes";
import storageType from "../../utils/storageService";
import Auth from "./Auth";

interface AuthProviderProps {
  children: ReactNode;
}

type AuthContextType = [Auth, React.Dispatch<React.SetStateAction<Auth>>];

const hasToken: string | null = storageType(
  process.env.REACT_APP_STORAGE ? process.env.REACT_APP_STORAGE : "local"
).getItem(`${process.env.REACT_APP_NAME}-token`);

const initState: Auth = {
  isLoggedIn: !!hasToken,
  isSuperAdmin: false,
  user: {
    _id: "",
    email: "",
    firstName: "",
    lastName: "",
    profilePictureUrl: "",
    registrationDate: "",
    lastEditDate: "",
    role: "su",
  },
  login(email: string, password: string): Promise<CognitoUser | undefined> {
    throw new Error("Function not implemented.");
  },
  oAuthLogin(code: string): void {
    throw new Error("Function not implemented.");
  },
  oAuthTokenRefresh(): Promise<string | undefined> {
    throw new Error("Function not implemented.");
  },
  confirmNewPassword(
    user: CognitoUser,
    newPassword: string
  ): Promise<CognitoUser | undefined> {
    throw new Error("Function not implemented.");
  },
  resetPasswordRequest(email: string): Promise<void> {
    throw new Error("Function not implemented.");
  },
  submitResetPassword(
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> {
    throw new Error("Function not implemented.");
  },
  changePassword(
    user: CognitoUser,
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    throw new Error("Function not implemented.");
  },
  getCurrentUser(): Promise<CognitoUser | undefined> {
    throw new Error("Function not implemented.");
  },
  getAttributes(): Promise<CognitoUserAttribute[] | undefined> {
    throw new Error("Function not implemented.");
  },
  oAuthAttributes(): Promise<UserAttributes | undefined> {
    throw new Error("Function not implemented.");
  },
  logout(): Promise<void> {
    throw new Error("Function not implemented.");
  },
  oAuthLogout(): void {
    throw new Error("Function not implemented.");
  },
  setIsLoggedIn(state: boolean): void {
    throw new Error("Function not implemented.");
  },
  getIsLoggedIn(): boolean {
    throw new Error("Function not implemented.");
  },
  getUser(): Promise<User> {
    throw new Error("Function not implemented.");
  },
  getIsSuperAdmin(): boolean {
    throw new Error("Function not implemented.");
  },
};
const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const authState = useState<Auth>(initState);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("Please wrap this function into the AuthProvider");
  }
  return context;
}
export { AuthProvider, useAuthContext };
