import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Lottie from "lottie-react";
import { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import noElementsIllustration from "../../../assets/animations/no-elements-animation.json";
import { PaginationInfo } from "../../../models/Pagination";
import { GetProject, Project } from "../../../models/Project";
import { get } from "../../../services/Api/ApiFunctions";
import useAuth from "../../../services/Auth/AuthService";
import ProjectsCreationDialog from "./ProjectsCreationDialog/ProjectsCreationDialog";
import "./ProjectsList.css";

interface FormValues {
  name: string;
}

export default function ProjectsList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit } = useForm<FormValues>({ mode: "all" });
  const [projects, setProjects] = useState<Project[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    searchParams.get("page")
      ? parseFloat(searchParams.get("page") as string)
      : 0
  );
  const [nameFilter, setNameFilter] = useState<string>(
    searchParams.get("name") ? (searchParams.get("name") as string) : ""
  );
  const [updated, setUpdated] = useState<boolean>(true);
  const [openProjectsCreationDialog, setOpenProjectsCreationDialog] =
    useState<boolean>(false);
  const [userRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    auth
      .getUser()
      .then((res) => {
        if (res) {
          setUserRole(res.role);
        }
      })
      .catch(() => {
        toast.error(t("errors.generic-error"));
      });
  }, []);

  useEffect(() => {
    if (updated) {
      get<GetProject>(
        "/projects",
        new URLSearchParams(
          `start=${10 * pageNumber}&name=${nameFilter}&getTotalCount=true`
        )
      )
        .then((res) => {
          setProjects(res.data.items);
          if (res.data.paginationInfo && !paginationInfo) {
            setPaginationInfo(res.data.paginationInfo);
            if (res.data.totalCount) {
              setTotalCount(res.data.totalCount);
            }
          }
          setUpdated(false);
          setIsLoading(false);
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          setIsLoading(false);
          if (e.response.status === 401) {
            toast.error(t("errors.unauthorized"));
          } else {
            toast.error(t("errors.generic-error"));
          }
        });
    }
  }, [pageNumber, updated]);

  const onSubmit: SubmitHandler<FormValues> = async (
    data: FormValues,
    event
  ) => {
    event?.preventDefault();
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("name", nameFilter);
    setSearchParams(updatedSearchParams.toString());
    setSearchParams(new URLSearchParams(`name=${nameFilter}`));
    setPaginationInfo(undefined);
    setPageNumber(0);
    setUpdated(true);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPageNumber(value - 1);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("page", String(value - 1));
    setSearchParams(updatedSearchParams.toString());
    setSearchParams(new URLSearchParams(`page=${value - 1}`));
    setUpdated(true);
  };

  const handleClose = async (returnValue: string) => {
    setOpenProjectsCreationDialog(false);
    if (returnValue === "created") {
      setSearchParams("");
      setNameFilter("");
      setPaginationInfo(undefined);
      setPageNumber(0);
      setUpdated(true);
    }
  };

  return (
    <Box sx={{ width: "95%", margin: "6rem auto" }} className="layoutMinHeight">
      <ProjectsCreationDialog
        open={openProjectsCreationDialog}
        onClose={handleClose}
      />
      <Grid container sx={{ mb: 3 }}>
        <Grid
          item
          md={3}
          xs={12}
          sx={{ alignSelf: "center", mb: isMobile ? 2 : 0 }}
        >
          <Typography variant="h5">{t("projects.projects-list")}</Typography>
        </Grid>
        {isMobile ? null : <Grid item md={5} />}
        <Grid item md={4} sm={12} xs={12}>
          {userRole === "sa" && (
            <Grid
              container
              sx={{
                textAlign: isMobile ? "auto" : "right",
                justifyContent: "space-between",
              }}
            >
              <Grid item md={6} sm={12} xs={12} sx={{ mb: isMobile ? 2 : 0 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "25px",
                    color: "secondary.main",
                  }}
                  onClick={() => navigate("/users-management")}
                >
                  {t("projects.users-management")}
                </Button>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "25px", color: "secondary.main" }}
                  onClick={() => setOpenProjectsCreationDialog(true)}
                >
                  {t("projects.create-project")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            mb: 5,
            width: "fit-content",
            borderRadius: "16px",
          }}
        >
          <CardContent sx={{ p: "0 !important" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="filled"
                label={`${t("projects.name")}`}
                {...register("name")}
                data-cy="nameFilterField"
                className="name-filter-field"
                onChange={(event) => {
                  setNameFilter(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ minHeight: "56px", borderRadius: 0 }}
                    >
                      <SearchIcon />
                    </Button>
                  ),
                }}
              />
            </form>
          </CardContent>
        </Card>
        <Button
          variant="contained"
          onClick={() => {
            setSearchParams("");
            setNameFilter("");
            setPaginationInfo(undefined);
            setPageNumber(0);
            setUpdated(true);
          }}
          sx={{ borderRadius: "25px", mb: 5 }}
        >
          <RefreshIcon />
        </Button>
      </Box>
      {projects.map((project) => (
        <Card
          sx={{
            mb: 5,
            cursor: "pointer",
          }}
          // eslint-disable-next-line no-underscore-dangle
          onClick={() => navigate(`/projects/${project._id}`)}
          // eslint-disable-next-line no-underscore-dangle
          key={project._id}
        >
          <CardContent sx={{ pr: 0, pb: "16px !important" }}>
            <Box display="flex" sx={{ justifyContent: "space-between" }}>
              <Box display="flex" sx={{ alignSelf: "center" }}>
                <Typography>{project.name}</Typography>
              </Box>
              <Box>
                <Grid container gap={3}>
                  <Grid item sx={{ mr: "-0.5rem" }}>
                    <Grid container direction="column">
                      <Grid item>
                        {project.languages.length} {t("projects.languages")}
                      </Grid>
                      <Grid item>
                        {project.languages[0].publishedCount}{" "}
                        {t("projects.keys")}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    className="arrow"
                    item
                    sx={{
                      backgroundColor: "#1976D2",
                      position: "sticky",
                      mt: "-16px",
                      mb: "-16px",
                    }}
                  >
                    <ArrowForwardIcon
                      sx={{ mt: 3.5, ml: 1, mr: 1, color: "#ffffff" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
      {projects && !projects.length && !isLoading && (
        <Box sx={{ textAlign: "center" }}>
          <Lottie
            animationData={noElementsIllustration}
            style={{
              width: "256px",
              margin: "0 auto",
            }}
          />
          <Typography variant="h5">{t("projects.no-projects")}</Typography>
        </Box>
      )}
      {projects && projects.length !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
          <Pagination
            count={Math.ceil(totalCount / 10)}
            page={pageNumber + 1}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </Box>
  );
}
