import { SxProps } from "@mui/material";

const LoadingSpinnerStyle = {
  LoadingSpinnerContainer(): SxProps {
    return {
      height: "100vh",
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "9999",
    };
  },
};

export default LoadingSpinnerStyle;
