import axios from "axios";
import { useEffect, useState } from "react";
import useLoadingSpinnerContext from "../LoadingSpinner/LoadingSpinnerService";

export default function ResponseInterceptor() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const loadingSpinner = useLoadingSpinnerContext();

  const initializeInterceptor = () => {
    setIsActive(true);

    return axios.interceptors.response.use(
      // intercept response OK
      (response) => {
        if (!response.config.url?.includes("dev-translation-platform")) {
          loadingSpinner.addResponse();
        }
        // execute http api call
        return Promise.resolve(response);
      },
      (error) => {
        // intercept response KO
        if (!error.config.url?.includes("dev-translation-platform")) {
          loadingSpinner.addResponse();
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    if (!isActive) {
      initializeInterceptor();
    }
  }, []);

  return null;
}
