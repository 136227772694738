import { Draggable } from "react-beautiful-dnd";

import { DragIndicator } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Flag from "react-world-flags";
import favoriteIllustration from "../../assets/animations/favorite-animation.json";
import { ReactComponent as Branch } from "../../assets/images/branch.svg";
import { ProjectLanguage } from "../../models/Language";

export type DraggableListItemProps = {
  item: ProjectLanguage;
  index: number;
  baseLanguage: boolean;
  handleDelete: (
    value: { code: string; label?: string },
    type: "add" | "remove" | "closed"
  ) => void;
};

function DraggableListItem({
  item,
  index,
  baseLanguage,
  handleDelete,
}: DraggableListItemProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Draggable draggableId={item.code} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{ background: snapshot.isDragging ? "rgb(235,235,235)" : "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {index !== 0 && (
                <SvgIcon
                  sx={{
                    height: "48px",
                    mt: "-1.25rem",
                    ml: "0.4rem",
                    mr: "0.5rem",
                  }}
                >
                  <Branch />
                </SvgIcon>
              )}
              <ListItemAvatar>
                <Flag
                  code={
                    item.code.split("-")[1] === "**"
                      ? item.code.split("-")[0]
                      : item.code.split("-")[item.code.split("-").length - 1]
                  }
                  style={{
                    width: "2em",
                    height: "2em",
                    marginTop: index === 0 ? "0.1em" : "0.25em",
                  }}
                  fallback={
                    <img
                      alt={t("alt.unknown-flag")}
                      // eslint-disable-next-line global-require
                      src={require("../../assets/images/flag_with_question_mark.png")}
                      style={{
                        width: "3em",
                        height: "2em",
                        marginTop: index === 0 ? "0.1em" : "0.25em",
                        marginRight: "1em",
                      }}
                    />
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${
                  item.code.split("-")[1].substring(0, 2) === "**"
                    ? item.code.split("-")[0]
                    : item.code
                }${item.label ? ` (${item.label})` : ""}${
                  baseLanguage && index === 0
                    ? ` (${t("languages.base-language")})`
                    : ""
                }`}
                sx={{ placeSelf: "center", ml: "-0.5rem" }}
              />
              {baseLanguage && index === 0 ? (
                <Lottie
                  animationData={favoriteIllustration}
                  style={{
                    width: "20px",
                    height: "20px",
                    placeSelf: "center",
                    marginLeft: "0.5rem",
                  }}
                  loop={false}
                />
              ) : null}
            </Box>
            <Box sx={{ display: "flex" }}>
              <span style={{ marginRight: "1.5rem", placeSelf: "center" }}>
                {item.draftCount} {t("languages.draft")}
              </span>
              <span style={{ marginRight: "1.5rem", placeSelf: "center" }}>
                {item.publishedCount} {t("languages.published")}
              </span>
              <Button
                variant="contained"
                sx={{ borderRadius: "16px", mr: 2 }}
                onClick={() =>
                  navigate(
                    `${location.pathname}/translations/${
                      item.code.split("-")[1].substring(0, 2) === "**"
                        ? item.code.split("-")[0]
                        : item.code
                    }`
                  )
                }
              >
                {t("languages.manage")}
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ borderRadius: "16px", mr: 2 }}
                onClick={() => handleDelete({ code: item.code }, "remove")}
              >
                {t("languages.remove")}
              </Button>
              <div
                {...provided.dragHandleProps}
                style={{ placeSelf: "center" }}
              >
                <DragIndicator />
              </div>
            </Box>
          </Box>
        </ListItem>
      )}
    </Draggable>
  );
}

export default DraggableListItem;
