import { createTheme as createMuiTheme } from "@mui/material";
import createThemeComponents from "./components";
import { lightPalette } from "./palette";
import typography from "./typography";

const createTheme = () => {
  const palette = lightPalette;

  // Create base theme
  const baseTheme = createMuiTheme({
    palette,
    typography,
  });

  // Inject base theme to be used in components
  return createMuiTheme(
    {
      components: createThemeComponents(baseTheme),
    },
    baseTheme
  );
};

export default createTheme;
